import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify styles
import SummaryApi from '../common';
import Context from '../context';
import Cookies from 'js-cookie'; // Import js-cookie library
import { useNavigate } from 'react-router-dom';

const OTPVerification = ({ onClose, email }) => {
    const [data, setData] = useState({
        email: email,
        otp: ''
    });
    const navigate = useNavigate();
    const { fetchUserAddToCart } = useContext(Context);
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleVerifyOTP = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(SummaryApi.verifyOTP.url, {
                method: SummaryApi.verifyOTP.method, // Use PUT method for OTP verification
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data.email,
                    otp: data.otp,
                }),
            });

            if (!response.ok) {
                throw new Error('OTP verification failed');
            }

            const responseData = await response.json();

            // Assuming the API sends a success message or status
            if (responseData.status) {
                toast.success(responseData.message);
                // Store customerid in a cookie without expiration
                Cookies.set('M8V5R1JQ3K0PWT6Z9LXA', btoa(encodeURIComponent(responseData.customerid)), {   //M8V5R1JQ3K0PWT6Z9LXA(Random 16-character hashcode)
                    expires: 365,  // Cookie will expire in 365 days (1 year)
                    secure: true,  // Cookie will only be sent over HTTPS
                    sameSite: 'Strict'  // Protect against CSRF
                });
                console.log(responseData.customerid)
                // Navigate to home page
                
                navigate('/');
                fetchUserAddToCart()
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error('Error verifying OTP. Please try again.');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="bg-white p-5 w-full max-w-md mx-auto rounded-lg">
                <form onSubmit={handleVerifyOTP}>
                    <div className="grid">
                        <label>Email:</label>
                        <div className="bg-gray-100 p-2 border">
                            <input
                                type="text"
                                placeholder="Enter your mobile number"
                                name="email"
                                value={data.email}
                                onChange={handleOnChange}
                                required
                                className="w-full h-full outline-none bg-transparent"
                                disabled // Disable input to prevent changing mobile number
                            />
                        </div>
                    </div>
                    <div className="grid">
                        <label>OTP:</label>
                        <div className="bg-gray-100 p-2 border">
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                name="otp"
                                value={data.otp}
                                onChange={handleOnChange}
                                required
                                className="w-full h-full outline-none bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="flex justify-between mt-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-full mr-4"
                        >
                            Close
                        </button>
                        <button
                            type="submit"
                            className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full"
                        >
                            Verify
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OTPVerification;
