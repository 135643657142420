import React, { useState, useEffect } from 'react';
import { CgClose } from 'react-icons/cg';
import { FaCloudUploadAlt } from 'react-icons/fa';
import DisplayImage from './DisplayImage';
import { MdDelete } from 'react-icons/md';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import Switch from "react-switch";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const UnitUploadForm = ({ onClose, product, fetchData }) => {
  const modules = {
    toolbar: [
      [{ 'list': 'bullet' }]
    ]
  };
  const [data, setData] = useState({
    unittype: '',
    image1: {
      file: null,
      url: ''
    },
    image2: {
      file: null,
      url: ''
    },
    image3: {
      file: null,
      url: ''
    },
    image4: {
      file: null,
      url: ''
    },
    about: '',
    discount: '',
    price: '',
    afterdiscountprice: '',
    isactive: false,
    feature: '',
  });
  const [loading, setLoading] = useState(false); // Add loading state
  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState('');

  // Function to check if an image input should be enabled based on previous ones
  const isImageInputEnabled = (imageIndex) => {
    if (imageIndex === 1) {
      return true; // Always enable the first image input
    }
    // Enable the current image input only if the previous one has a file uploaded
    const previousImageKey = `image${imageIndex - 1}`;
    return !!data[previousImageKey].file;
  };

  // Handle changes in the form inputs
  const handleOnChange = async (e) => {
    const { name, value } = e.target;

    if (name === 'price' || name === 'discount') {
      // Parse input values to numbers
      const parsedValue = parseFloat(value);

      // Update state based on the input field changed
      setData((prev) => ({
        ...prev,
        [name]: parsedValue // Update the specific field with parsed value
      }));

      // Calculate afterdiscountprice when price or discount changes
      const price = parseFloat(name === 'price' ? parsedValue : data.price);
      const discount = parseFloat(name === 'discount' ? parsedValue : data.discount);
      const afterDiscountPrice = price - (price * discount) / 100;

      setData((prev) => ({
        ...prev,
        afterdiscountprice: Math.round(afterDiscountPrice)
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSpecificationsChange = (value) => {
    setData((prev) => ({
      ...prev,
      feature: value,
    }));
    console.log(data)
  };
  useEffect(() => {
    if (!data.feature) {
      handleSpecificationsChange('<ul><li>Enter bullet items here</li></ul>');
    }
  }, [data.feature, handleSpecificationsChange]);

  // Handle active toggle switch
  const handleActiveToggle = (checked) => {
    setData((prev) => ({
      ...prev,
      isactive: checked
    }));
  };

  // Handle uploading product image
  const handleUploadProduct = async (e, imageKey) => {
    const file = e.target.files[0];

    setData(prev => ({
      ...prev,
      [imageKey]: {
        file: file,
        url: URL.createObjectURL(file)
      }
    }));
  };

  // Handle deleting product image
  const handleDeleteProductImage = (imageKey) => {
    setData(prev => ({
      ...prev,
      [imageKey]: {
        file: null,
        url: ''
      }
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when form is submitted
    // Ensure at least one image is uploaded
    if (!data.image1.file && !data.image2.file && !data.image3.file && !data.image4.file) {
      toast.error('Image upload required');
      setLoading(false); // Reset loading state if there's an error
      return;
    }

    try {
      const formData = new FormData();
      formData.append('productid', product.product_id);
      formData.append('unittype', data.unittype);
      formData.append('about', data.about);
      if (data.feature == '<ul><li>Enter bullet items here</li></ul>') {
        formData.append('feature', '');
      }else{
        formData.append('feature', data.feature);
      }
      formData.append('discount', data.discount);
      formData.append('price', data.price);
      formData.append('afterdiscountprice', data.afterdiscountprice);

      // Append images
      formData.append('image1', data.image1.file);
      formData.append('image2', data.image2.file);
      formData.append('image3', data.image3.file);
      formData.append('image4', data.image4.file);

      formData.append('isactive', data.isactive ? 1 : 0);

      const response = await fetch(SummaryApi.uploadProductUnit.url, {
        method: SummaryApi.uploadProductUnit.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload product');
      }

      const responseData = await response.json();

      if (responseData.status) {
        toast.success(responseData.message);
        onClose();     // Close modal or form
        fetchData();   // Refetch data or update product list
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error('Error uploading product');
      console.error('Error:', error);
    }finally {
      setLoading(false); // Reset loading state after response
    }
  };

  // Function to get the most recently uploaded image key
  const getMostRecentImageName = () => {
    // Determine the most recent uploaded image key
    for (let i = 4; i >= 1; i--) {
      const imageName = `image${i}`;
      if (data[imageName].file) {
        return imageName;
      }
    }
    return null;
  };

  const mostRecentImageName = getMostRecentImageName();

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] overflow-hidden'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Upload Product Unit</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 overflow-y-scroll h-full pb-5' onSubmit={handleSubmit}>
          <label htmlFor='unittype'>Unit Name :</label>
          <input
            type='text'
            id='unittype'
            placeholder='Enter product name'
            name='unittype'
            value={data.unittype}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
            
          />
          <label htmlFor='image' className='mt-3'>
            Product Image (1-4 images required)
          </label>
          {/* Upload inputs for each image key */}
          <div className='grid grid-cols-4 gap-4 mt-2'>
            {['image1', 'image2', 'image3', 'image4'].map((imageKey, index) => (
              <label htmlFor={`uploadImageInput_${imageKey}`} key={imageKey}>
                <div className={`p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer${loading ? 'cursor-not-allowed' : ''} ${!isImageInputEnabled(index + 1) && 'opacity-50 pointer-events-none'}`}>
                  <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                    <span className='text-4xl'>
                      <FaCloudUploadAlt />
                    </span>
                    <p className='text-sm'>Upload Product Image {index + 1}</p>
                    <input
                      type='file'
                      id={`uploadImageInput_${imageKey}`}
                      className='hidden'
                      onChange={(e) => handleUploadProduct(e, imageKey)}
                      disabled={!isImageInputEnabled(index + 1)}
                    />
                  </div>
                </div>
              </label>
            ))}
          </div>
          <div>
            {/* Check if any image URL exists in data */}
            {['image1', 'image2', 'image3', 'image4'].some((imageName) => data[imageName].url) ? (
              <div className='flex items-center gap-2 mt-4'>
                {/* Map over image data objects */}
                {['image1', 'image2', 'image3', 'image4'].map((imageName) => (
                  <div className='relative group' key={imageName}>
                    {/* Render image if URL exists */}
                    {data[imageName].url && (
                      <img
                        src={data[imageName].url}
                        alt={`Product Image ${imageName}`}
                        width={80}
                        height={80}
                        className='bg-slate-100 border cursor-pointer'
                        onClick={() => {
                          setOpenFullScreenImage(true);
                          setFullScreenImage(data[imageName].url);
                        }}
                      />
                    )}
                    {/* Render delete button if URL exists */}
                    {imageName === mostRecentImageName && (
                      <div
                        className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full cursor-pointer'
                        onClick={() => handleDeleteProductImage(imageName)}
                      >
                        <MdDelete />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              // Render message if no images are uploaded
              <p className='text-red-600 text-xs mt-2'>*Please upload product images</p>
            )}
          </div>

          <label htmlFor='price' className='mt-3'>
            Price :
          </label>
          <input
            type='number'
            id='price'
            placeholder='Enter price'
            value={data.price}
            name='price'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />
          <label htmlFor='discount' className='mt-3'>
            Discount :
          </label>
          <input
            type='number'
            id='discount'
            placeholder='Enter discount'
            value={data.discount}
            name='discount'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required          
          />
          <label htmlFor='afterdiscountprice' className='mt-3'>
            Selling Price :
          </label>
          <input
            type='number'
            id='afterdiscountprice'
            placeholder='Selling price'
            value={data.afterdiscountprice}
            name='afterdiscountprice'
            onChange={handleOnChange}
            className='p-2 bg-yellow-100 border rounded font-medium text-red-700'
            required
            disabled 
          />
          <label htmlFor='about' className='mt-3'>
            Description :
          </label>
          <textarea
            className='h-28 bg-slate-100 border resize-none p-1'
            placeholder='Enter product description'
            rows={3}
            onChange={handleOnChange}
            name='about'
            value={data.about}></textarea>
          <label htmlFor='feature' className='mt-3'>
            Specifications :
          </label>
          <div>
            <ReactQuill
              value={data.feature}
              onChange={handleSpecificationsChange}
              modules={modules}
              className='bg-slate-100 border rounded'
            />
          </div>
          <div className="flex py-3">
            <Switch
              onChange={handleActiveToggle}
              checked={data.isactive}
              height={24} 
              width={78} 
              handleDiameter={20} 
              handleStyle={{
                backgroundColor: '#4caf50',
                boxShadow: '0 0 2px 3px rgba(0,0,0,0.1)', 
              }}
              onColor="#008000" 
              offColor="#D22B2B" 
              checkedIcon={<div className="flex justify-center items-center h-full text-sm ml-3 text-white"> Active </div>}
              uncheckedIcon={<div className="flex justify-center items-center h-full text-sm mr-5 text-white"> InActive </div>}
              className="mt-2"  
            />
          </div>
          <button type="submit" className='px-3 py-2 bg-red-600 text-white mb-10 hover:bg-red-700' >
            Upload Unit Product
          </button>
        </form>
        {loading && (
          <div className='absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center'>
            <div className='text-lg font-bold'>Uploading Unit Product...</div>
          </div>
        )}
      </div>
      {/* Display image full screen */}
      {openFullScreenImage && <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />}
    </div>
  );
};

export default UnitUploadForm;