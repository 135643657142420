import React, { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { FaCloudUploadAlt, FaRegStar, FaStar } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import DisplayImage from './DisplayImage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the Quill CSS for styling

const AdminEditProduct = ({ onClose, productData, fetchdata }) => {
  const modules = {
    toolbar: [
      [{ 'list': 'bullet' }]
    ]
  };
  const initialData = {
    productid: productData?.product_id,
    productcode: productData?.product_code,
    productname: productData?.product_name || '',
    subtitle: productData?.sub_title || '',
    brandid: productData?.brand_id || '',
    categoryid: productData?.category_id || '',
    subcategoryid: productData?.subcatg_id || '',
    rating: productData?.rating || '',
    isdisplay: productData?.is_display || false,
    isactive: productData?.is_active === "0" ? false : true,
    bestseller: productData?.best_seller || false,
    homedisplay: productData?.home_display || false,
  };
  const [data, setData] = useState(initialData);
  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [fullScreenImage, setFullScreenImage] = useState('');
  const [allBrand, setAllBrand] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state

  // Fetch all categories
  const fetchAllCategory = async () => {
    try {
      const response = await fetch(SummaryApi.allCategory.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
      });
      const dataResponse = await response.json();
      setAllCategory(dataResponse?.data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch all brands
  const fetchAllBrand = async () => {
    try {
      const response = await fetch(SummaryApi.allBrand.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
      });
      const dataResponse = await response.json();
      setAllBrand(dataResponse?.data || []);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  // Fetch sub-categories based on category ID
  const fetchAllSubCategory = async (categoryid) => {
    try {
      const response = await fetch(SummaryApi.allSubCategoryByID.url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ categoryid }),
      });

      const dataResponse = await response.json();

      if (dataResponse.status) {
        setAllSubCategory(dataResponse.data || []);
      } else {
        console.error('Failed to fetch subcategories:', dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const fetchSubCategory = async (categoryid) => {
    try {
      const response = await fetch(SummaryApi.allSubCategoryByID.url, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ categoryid }),
      });

      const dataResponse = await response.json();

      if (dataResponse.status) {
        setAllSubCategory(dataResponse.data || []);
      } else {
        console.error('Failed to fetch subcategories:', dataResponse.message);
      }
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  useEffect(() => {
    fetchAllCategory();
    fetchAllBrand();
    fetchSubCategory(data.categoryid);
  }, []);

  const handleOnChange = async (e) => {
    const { name, value } = e.target;


    if (name === 'categoryid') {
      setAllSubCategory([]);
      setData(prev => ({
        ...prev,
        [name]: value,
        subcategoryid: '', // Reset sub-category when category changes
      }));
      await fetchAllSubCategory(value);
    } else if (name === 'price' || name === 'discount') {
      // Parse input values to numbers
      const parsedValue = parseFloat(value);

      // Update state based on the input field changed
      setData((prev) => ({
        ...prev,
        [name]: parsedValue // Update the specific field with parsed value
      }));

      // Calculate afterdiscountprice when price or discount changes
      const price = parseFloat(name === 'price' ? parsedValue : data.price);
      const discount = parseFloat(name === 'discount' ? parsedValue : data.discount);
      const afterDiscountPrice = price - (price * discount) / 100;

      setData((prev) => ({
        ...prev,
        afterdiscountprice: Math.round(afterDiscountPrice)
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleActiveToggle = (checked) => {
    setData(prev => ({
      ...prev,
      isactive: checked,
    }));
  };

  const handleDisplayToggle = (checked) => {
    setData(prev => ({
      ...prev,
      isdisplay: checked,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data)
    setLoading(true); // Set loading to true when form is submitted
    try {
      const formData = new FormData();
      formData.append('productid', data.productid);
      formData.append('productname', data.productname);
      formData.append('subtitle', data.subtitle);
      formData.append('brandid', data.brandid);
      formData.append('categoryid', data.categoryid);
      formData.append('subcategoryid', data.subcategoryid);
      formData.append('rating', data.rating);
      formData.append('isdisplay', data.isdisplay ? 1 : 0);
      formData.append('isactive', data.isactive ? 1 : 0);
      formData.append('bestseller', data.bestseller ? 1 : 0);
      formData.append('homedisplay', data.homedisplay ? 1 : 0);

      // Make API call
      const response = await fetch(SummaryApi.updateProduct.url, {
        method: SummaryApi.updateProduct.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
        },
        body: formData,
      });

      const responseData = await response.json();

      if (responseData.status) {
        toast.success(responseData.message);
        onClose();
        fetchdata();
      } else {
        toast.error(responseData.message);
        fetchAllBrand(responseData.active)
        if (responseData.active) {
          data.isactive = true
        }
      }
    } catch (error) {
      console.error('Error updating product:', error);
      toast.error('Failed to update product');
    } finally {
      setLoading(false); // Reset loading state after response
    }
  };

  const handleCheckboxChange = (e) => {
    setData({
      ...data,
      bestseller: e.target.checked ? 1 : 0
    });
  };
  const handleCheckbox = (e) => {
    setData({
      ...data,
      homedisplay: e.target.checked ? 1 : 0
    });
  };

  const handleStarClick = (ratingValue) => {
    setData((prev) => ({
      ...prev,
      rating: ratingValue,
    }));
  };



  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10'>
      <div className='bg-white p-4 rounded w-full max-w-2xl h-full max-h-[80%] relative overflow-y-auto'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Update Product</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 h-full pb-5' onSubmit={handleSubmit}>
          <label htmlFor='productcode'>Product Code :</label>
          <input
            type='text'
            id='productcode'
            placeholder='Enter product name'
            name='productcode'
            value={data.productcode}
            onChange={handleOnChange}
            className='p-2 bg-yellow-100 border rounded'
            required
            disabled
          />
          <label htmlFor='productname'>Product Name :</label>
          <input
            type='text'
            id='productname'
            placeholder='Enter product name'
            name='productname'
            value={data.productname}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='subtitle'>Subtitle :</label>
          <input
            type='text'
            id='subtitle'
            placeholder='Enter subtitle'
            name='subtitle'
            value={data.subtitle}
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'
            required
          />

          <label htmlFor='brandid' className='mt-3'>
            Brand Name :
          </label>
          <select
            value={data.brandid}
            name='brandid'
            onChange={handleOnChange}
            className='p-2 bg-slate-100 border rounded'>
            <option value={''}>Select Brand</option>
            {allBrand.map((el, index) => (
              <option value={el.brand_id} key={el.brand_id}>
                {el.brand_name}
              </option>
            ))}
          </select>

          <div className='flex'>
            <div className='w-1/2 pr-2'>
              <label htmlFor='categoryid' className='block mt-3'>
                Category :
              </label>
              <select
                required
                value={data.categoryid}
                name='categoryid'
                onChange={handleOnChange}
                className='p-2 bg-slate-100 border rounded w-full'>
                <option value={''}>Select Category</option>
                {allCategory.map((el, index) => (
                  <option value={el.category_id} key={el.category_id}>
                    {el.category_name}
                  </option>
                ))}
              </select>
            </div>
            <div className='w-1/2 pl-2'>
              <label htmlFor='subcategoryid' className='block mt-3'>
                Sub Category :
              </label>
              <select
                value={data.subcategoryid}
                name='subcategoryid'
                onChange={handleOnChange}
                className='p-2 bg-slate-100 border rounded w-full'>
                <option value={''}>Select Sub Category</option>
                {allSubCategory.map((el, index) => (
                  <option value={el.subcatg_id} key={el.subcatg_id}>
                    {el.subcatg_name}
                  </option>
                ))}
              </select>
            </div>
          </div>



          <div className='flex mt-3'>
            <label htmlFor='rating' className='block mr-2'>Rating:</label>
            <div className='flex items-center'>
              {[...Array(5)].map((star, index) => {
                const ratingValue = index + 1;
                return (
                  <label key={index}>
                    <input
                      type='radio'
                      name='rating'
                      value={ratingValue}
                      onClick={() => handleStarClick(ratingValue)}
                      className='hidden'
                    />
                    {ratingValue <= data.rating ? (
                      <FaStar className='text-orange-500 cursor-pointer text-2xl' />
                    ) : (
                      <FaRegStar className='text-orange-500 cursor-pointer text-2xl' />
                    )}
                  </label>
                );
              })}
            </div>
          </div>

          <div className='flex justify-center py-3'>
            <Switch
              onChange={handleDisplayToggle}
              checked={data.isdisplay == 1}
              height={24}
              width={95}
              handleDiameter={20}
              handleStyle={{
                backgroundColor: '#4caf50',
                boxShadow: '0 0 2px 3px rgba(0,0,0,0.1)',
              }}
              onColor='#008000'
              offColor='#D22B2B'
              checkedIcon={<div className='flex justify-center items-center h-full text-sm ml-10 text-white'> DisplayOn </div>}
              uncheckedIcon={<div className='flex justify-center items-center h-full text-sm mr-10 text-white'> DisplayOff </div>}
              className='mt-2'
            />

            <Switch
              onChange={handleActiveToggle}
              checked={data.isactive == 1}
              height={24}
              width={78}
              handleDiameter={20}
              handleStyle={{
                backgroundColor: '#4caf50',
                boxShadow: '0 0 2px 3px rgba(0,0,0,0.1)',
              }}
              onColor='#008000'
              offColor='#D22B2B'
              checkedIcon={<div className='flex justify-center items-center h-full text-sm ml-3 text-white'> Active </div>}
              uncheckedIcon={<div className='flex justify-center items-center h-full text-sm mr-5 text-white'> InActive </div>}
              className='mt-2 ml-4'
            />
          </div>

          <div className='flex justify-between'>
            <div>
              <input
                type='checkbox'
                id='bestseller'
                name='bestseller'
                checked={data.bestseller == 1}
                onChange={handleCheckboxChange}
                className='mr-2'
              />
              <label htmlFor='bestseller'>Best Seller</label>
            </div>
            <div>
              <input
                type='checkbox'
                id='homedisplay'
                name='homedisplay'
                checked={data.homedisplay == 1}
                onChange={handleCheckbox}
                className='mr-2'
              />
              <label htmlFor='homedisplay'>Home Display</label>
            </div>
          </div>

          <button className='px-3 py-2 bg-red-600 text-white mb-10 hover:bg-red-700'>Update Product</button>
        </form>
        {loading && (
          <div className='absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center'>
            <div className='text-lg font-bold'>Updating Product...</div>
          </div>
        )}
      </div>

      {openFullScreenImage && <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />}
    </div>
  );
};

export default AdminEditProduct;
