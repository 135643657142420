import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import SummaryApi from '../common';
import AdminSubCategoryCard from '../components/AdminSubCategoryCard';
import UploadSubCategory from '../components/UploadSubCategory';

const AllSubCategory = () => {
  const [openUploadSubCategory, setOpenUploadSubCategory] = useState(false);
  const [allSubCategory, setAllSubCategory] = useState([]);

  const fetchAllSubCategory = async () => {
    const response = await fetch(SummaryApi.adminSubCategory.url, {
      headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
      },
    });
    const dataResponse = await response.json();
    setAllSubCategory(dataResponse?.data || []);
  };

  useEffect(() => {
    fetchAllSubCategory();
  }, []);

  return (
    <div className="p-4">
      <div className='bg-white py-2 px-4 flex flex-col sm:flex-row justify-between items-center mb-4 shadow-md border rounded-lg'>
        <h2 className='font-bold text-lg md:text-xl'>All Sub Categories</h2>

        <div className='flex flex-col md:flex-row items-center'>
          <div className='flex items-center mx-2 text-sm'>
            <div>Active</div>
            <div className='mx-2 text-lg text-green-500'><FaCheckCircle /></div>
            <div className='ml-2'>Inactive</div>
            <div className='mx-2 text-lg text-red-500'><FaTimesCircle /></div>
          </div>
          <button
            className='bg-red-600 text-white hover:bg-red-700 transition-all py-2 px-4 rounded-full mx-1 mb-2 mt-2 md:mt-0 md:mb-0'
            onClick={() => setOpenUploadSubCategory(true)}
          >
            Upload Sub Category
          </button>
        </div>
      </div>

      {/* Display sub categories using AdminSubCategoryCard */}
      <div className='flex items-center flex-wrap gap-5 py-4'>
        {allSubCategory.map((subCategory, index) => (
          <AdminSubCategoryCard
            key={index}
            data={subCategory}
            fetchdata={fetchAllSubCategory}
          />
        ))}
      </div>

      {/* Upload sub category component */}
      {openUploadSubCategory && (
        <UploadSubCategory
          onClose={() => setOpenUploadSubCategory(false)}
          fetchdata={fetchAllSubCategory}
        />
      )}
    </div>
  );
};

export default AllSubCategory;
