import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SummaryApi from '../common'; // Ensure this path is correct
import displayINRCurrency from '../helpers/displayCurrency';


const ProductGrid = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Helper function to encode product_id
  const encodeProductId = (id) => {
    if (!id) return '';
    return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
  };

  const fetchData = async () => {
    try {
      const response = await fetch(SummaryApi.getHomeDisplay.url, {
        headers: {
            'Authorization': 'Bearer oakyaecom_units_916',
            'Content-Type': 'application/json',
        },
    });
      const dataResponse = await response.json();
      const reversedData = (dataResponse?.data || []).reverse();
      setData(reversedData || []);
    } catch (error) {
      console.error("Error fetching explore more products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    const loadingItems = new Array(12).fill(null);
    return (
      <div className="container mx-auto px-4 pt-2 md:pt-4">
        <h2 className="text-xl font-bold md:mb-2">Explore More</h2>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {loadingItems.map((_, index) => (
            <div key={`placeholder-${index}`} className="bg-white rounded-sm border shadow-md overflow-hidden">
              <div className="h-28 md:h-48 animate-pulse flex justify-center items-center bg-gray-100">
                <div className="h-20 md:h-40"></div>
              </div>
              <div className="p-4 bg-slate-100">
                <div className="w-1/2 h-[10px] md:h-[12px] bg-gray-200 mb-1 md:mb-2 animate-pulse rounded-full"></div>
                <div className="w-full h-[10px] md:h-[12px] bg-gray-200 mb-1 md:mb-2 animate-pulse rounded-full"></div>
                <div className="w-3/4 h-[10px] md:h-[12px] bg-gray-200 mb-1 md:mb-2 animate-pulse rounded-full"></div>
                <div className="w-1/2 h-[10px] md:h-[12px] bg-gray-200 mb-1 md:mb-2 animate-pulse rounded-full"></div>
                <div className="flex gap-3 mb-1 md:mb-2">
                  <div className="w-1/4 h-[10px] md:h-[12px] bg-gray-200 animate-pulse rounded-full"></div>
                  <div className="w-1/4 h-[10px] md:h-[12px] bg-gray-200 animate-pulse rounded-full"></div>
                  <div className="w-1/4 h-[10px] md:h-[12px] bg-gray-200 animate-pulse rounded-full"></div>
                </div>
                <div className="w-1/2 h-7 md:h-10 bg-gray-200 animate-pulse rounded-full"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="container mx-auto p-4 text-center">
        <p>No products available.</p>
      </div>
    );
  }

  const renderStars = (rating) => {
    const stars = Array(5).fill(false).map((_, index) => index < rating ? '★' : '☆');
    return stars.join(' ');
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleAddToCart = (e, productId, productName) => {
    e.stopPropagation();
  };

  return (
    <div className="container mx-auto px-4 pt-2 md:pt-4 pb-2 md:pb-4">
      <h2 className="text-xl font-bold md:mb-2">Explore More</h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {data.map((product, index) => (
          <Link
            key={index}
            to={`/product/${encodeProductId(product?.product_id)}`}
            className="bg-white rounded-sm border shadow-md overflow-hidden"
            onClick={scrollTop}
          >
            <div className="h-28 md:h-48 flex justify-center items-center">
              <img
                src={`${SummaryApi.productUnitImage.url}${product?.unit_product_img1}`}
                className="object-contain h-20 md:h-40 transition-transform transform hover:scale-105"
                alt={product?.product_name}
              />
            </div>
            <div className="p-2 md:p-4 bg-slate-100">
              <h2 className="text-sm md:text-base text-ellipsis line-clamp-1 text-black mb-2">
                {product?.product_name}
              </h2>
              <p className="capitalize text-slate-500 mb-2">{product?.category?.category_name}</p>
              <div className="text-orange-500 flex items-center gap-1 mb-2">
                {renderStars(product?.rating)}
              </div>
              <div className="flex gap-3 mb-2">
                <p className="text-slate-500 text-sm md:text-base line-through">{displayINRCurrency(product?.price)}</p>
                <p className="text-black  text-sm md:text-base font-medium">{displayINRCurrency(product?.after_discount_price)}</p>
                <p className="hidden md:block text-green-700 text-sm md:text-base font-medium">
                  {Math.round(((product?.price - product?.after_discount_price) / product?.price) * 100)}% off
                </p>
              </div>
              <button
                className="text-sm h-7 md:h-10 bg-red-600 hover:bg-red-700 text-white px-3 py-0.5 rounded-lg"
                onClick={(e) => handleAddToCart(e, product?.product_id, product?.product_name)}
              >
                Add to Cart
              </button>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductGrid;
