import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import VerticalCard from '../components/VerticalCard';
import SummaryApi from '../common';

const CategoryProduct = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true); // New state for loading categories
  const navigate = useNavigate();
  const location = useLocation();
  const [allCategory, setAllCategory] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [expandLeftSide, setExpandLeftSide] = useState(false);

  // Fetch all categories
  const fetchAllCategory = async () => {
    setLoadingCategories(true); // Set loading state to true
    try {
      const response = await fetch(SummaryApi.allCategory.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
      });
      const dataResponse = await response.json();
      setAllCategory(dataResponse?.data || []);
    } finally {
      setLoadingCategories(false); // Set loading state to false
    }
  };

  // Fetch data based on filter
  const fetchData = async () => {
    if (filterCategoryList.length === 0) {
      setData([]); // Set data to null when no filter categories are selected
      return;
    }
    setLoading(true);
    console.log(123)
    try {
      const response = await fetch(SummaryApi.getProduct.url, {
        method: SummaryApi.getProduct.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          categoryname: filterCategoryList,
        }),
      });

      const dataResponse = await response.json();
      let fetchedData = dataResponse?.data || [];

      if (sortBy === 'asc') {
        fetchedData = fetchedData.sort((a, b) => a.after_discount_price - b.after_discount_price);
      } else if (sortBy === 'dsc') {
        fetchedData = fetchedData.sort((a, b) => b.after_discount_price - a.after_discount_price);
      }

      setData(fetchedData);
    } finally {
      setLoading(false);
    }
  };

  // Initialize state based on URL
  const initializeState = async () => {
    await fetchAllCategory();

    const urlSearch = new URLSearchParams(location.search);
    const urlCategoryListinArray = urlSearch.getAll('category');

    if (urlCategoryListinArray.length) {
      const urlCategoryListObject = {};
      urlCategoryListinArray.forEach((el) => {
        urlCategoryListObject[el] = true;
      });
      setSelectCategory(urlCategoryListObject);

      const arrayOfCategory = Object.keys(urlCategoryListObject).filter(
        (categoryKeyName) => urlCategoryListObject[categoryKeyName]
      );
      setFilterCategoryList(arrayOfCategory);
    }
  };

  const handleSelectCategory = (e) => {
    const { value, checked } = e.target;
    const updatedSelectCategory = {
      ...selectCategory,
      [value]: checked,
    };
    setSelectCategory(updatedSelectCategory);

    // Update filterCategoryList based on updatedSelectCategory
    const updatedFilterCategoryList = Object.keys(updatedSelectCategory).filter(
      (categoryKeyName) => updatedSelectCategory[categoryKeyName]
    );
    setFilterCategoryList(updatedFilterCategoryList);

    // Format URL with selected categories
    const urlFormat = updatedFilterCategoryList.map((el) => `category=${el}`).join('&');
    navigate(`/product-category?${urlFormat}`);
  };

  const handleOnChangeSortBy = (e) => {
    setSortBy(e.target.value);
  };

  const toggleExpandLeftSide = () => {
    setExpandLeftSide((prev) => !prev);

    if (expandLeftSide) {
      // Reset background scroll
      document.body.style.overflow = 'auto';
    } else {
      // Disable background scroll
      document.body.style.overflow = 'hidden';
    }
  };

  useEffect(() => {
    initializeState();
  }, []);

  useEffect(() => {
    fetchData();
  }, [filterCategoryList, sortBy]);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia('(min-width: 640px)').matches) {
        setExpandLeftSide(false); // Ensure left side is collapsed in desktop view
        document.body.style.overflow = 'auto'; // Reset scroll if needed
      }
    };

    handleResize(); // Set initial state based on current screen size

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container mx-auto p-3">
      <div className="hidden md:flex flex-col sm:flex-row min-h-screen">
        {/* Left side */}
        <div className={`bg-white border-r ${expandLeftSide ? 'w-full' : 'w-64'} transition-width duration-300`}>
          <div className="p-4">
            {/* Sort by */}
            <div>
              <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
                Sort by
              </h3>
              <form className="text-sm flex flex-col gap-2 py-2">
                <div className="flex items-center gap-3">
                  <input
                    type="radio"
                    name="sortBy"
                    checked={sortBy === 'asc'}
                    onChange={handleOnChangeSortBy}
                    value={'asc'}
                  />
                  <label>Price - Low to High</label>
                </div>
                <div className="flex items-center gap-3">
                  <input
                    type="radio"
                    name="sortBy"
                    checked={sortBy === 'dsc'}
                    onChange={handleOnChangeSortBy}
                    value={'dsc'}
                  />
                  <label>Price - High to Low</label>
                </div>
              </form>
            </div>
            {/* Filter by */}
            <div>
              <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
                Category
              </h3>
              <form className="text-sm flex flex-col gap-2 py-2">
                {loadingCategories ? (
                  <div className="flex flex-col gap-2">
                    {/* Placeholders for categories */}
                    {Array.from({ length: 20 }).map((_, index) => (
                      <div className="flex items-center gap-3" key={index}>
                        <Spin />
                        <span className="w-24 bg-gray-200 h-4 rounded"></span>
                      </div>
                    ))}
                  </div>
                ) : (
                  allCategory.map((categoryName, index) => (
                    <div className="flex items-center gap-3" key={index}>
                      <input
                        type="checkbox"
                        name="category"
                        checked={selectCategory[categoryName?.category_name] || false}
                        value={categoryName?.category_name}
                        id={categoryName?.category_name}
                        onChange={handleSelectCategory}
                      />
                      <label htmlFor={categoryName?.category_name}>
                        {categoryName?.category_name}
                      </label>
                    </div>
                  ))
                )}
              </form>
            </div>
          </div>
        </div>
        {/* Right side ( product ) */}
        <div className="flex-1 px-4">
          <div>
            <VerticalCard data={data} loading={loading} />
          </div>
        </div>
      </div>

      {/* Mobile version */}
      <div className="sm:hidden grid">
        {/* Expandable left side */}
        {expandLeftSide && (
          <div className="bg-white p-2 min-h-screen w-screen fixed top-0 left-0 z-50 overflow-y-auto max-h-screen">
            <div className="p-4">
              {/* Sort by */}
              <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
                Sort by
              </h3>
              <form className="text-sm flex flex-col gap-2 py-2">
                <div className="flex items-center gap-3">
                  <input
                    type="radio"
                    name="sortBy"
                    checked={sortBy === 'asc'}
                    onChange={handleOnChangeSortBy}
                    value={'asc'}
                  />
                  <label>Price - Low to High</label>
                </div>
                <div className="flex items-center gap-3">
                  <input
                    type="radio"
                    name="sortBy"
                    checked={sortBy === 'dsc'}
                    onChange={handleOnChangeSortBy}
                    value={'dsc'}
                  />
                  <label>Price - High to Low</label>
                </div>
              </form>
            </div>
            <div className="p-4">
              {/* Filter by */}
              <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-1 border-slate-300">
                Category
              </h3>
              <form className="text-sm flex flex-col gap-2 py-2">
                {loadingCategories ? (
                  <div className="flex flex-col gap-2">
                    {/* Placeholders for categories */}
                    {Array.from({ length: 20 }).map((_, index) => (
                      <div className="flex items-center gap-3" key={index}>
                        <Spin />
                        <span className="w-24 bg-gray-200 h-4 rounded"></span>
                      </div>
                    ))}
                  </div>
                ) : (
                  allCategory.map((categoryName, index) => (
                    <div className="flex items-center gap-3" key={index}>
                      <input
                        type="checkbox"
                        name="category"
                        checked={selectCategory[categoryName?.category_name] || false}
                        value={categoryName?.category_name}
                        id={categoryName?.category_name}
                        onChange={handleSelectCategory}
                      />
                      <label htmlFor={categoryName?.category_name}>
                        {categoryName?.category_name}
                      </label>
                    </div>
                  ))
                )}
              </form>
            </div>
          </div>
        )}

        {/* Button to expand/collapse left side */}
        <button
          className={`fixed ${expandLeftSide ? 'top-2' : 'top-[130px]'} right-2 bg-gray-500 text-white px-3 py-1 rounded-lg z-50`}
          onClick={toggleExpandLeftSide}
        >
          {expandLeftSide ? 'Collapse Filters' : 'Expand Filters'}
        </button>

        {/* Right side ( product ) */}
        <div className="flex-1 mt-7">
          <div>
            <VerticalCard data={data} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryProduct;
