import React, { useState } from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import AdminEditCategory from './AdminEditCategory';
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import SummaryApi from '../common';


const AdminCategoryCard = ({ data, fetchdata }) => {
  const [editCategory, setEditCategory] = useState(false);

  return (
    <div className='bg-white p-4 rounded relative'>
      {/* Conditional dot */}
      {data.is_active == 1 && (
        <div className='absolute top-2 right-2 text-md text-[#008000]'><FaCheckCircle /></div>
      )}
      {data.is_active == 0 && (
        <div className='absolute top-2 right-2 text-md text-[#D22B2B]'><FaTimesCircle /></div>
      )}


      <div className='w-40'>
        <div className='w-32 h-32 mx-auto flex justify-center items-center'>
          <img src={SummaryApi.image.url+data.category_image} className='object-fill h-full' alt={data.category} />
        </div>
        <h1 className='text-ellipsis line-clamp-2 text-center mt-2'>{data.category_name}</h1>

        {/* Edit Category button */}
        <div className='flex justify-end mt-2'>
          <div className='w-fit p-2 absolute bottom-2 right-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer' onClick={() => setEditCategory(true)}>
            <MdModeEditOutline />
          </div>
        </div>
      </div>

      {/* Edit Category component */}
      {editCategory && (
        <AdminEditCategory categoryData={data} onClose={() => setEditCategory(false)} fetchdata={fetchdata} />
      )}

    </div>
  );
};

export default AdminCategoryCard;
