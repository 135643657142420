// Testing

import React, { useState } from 'react';
import axios from 'axios';

const ProductForm = () => {
    const [formData, setFormData] = useState({
        productname: '',
        brandname: '',
        category: '',
        productimage: [],
        description: '',
        price: 0,
        sellingprice: 0,
        createddate: '',
        updateddate: ''
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'productimage') {
            const fileList = Array.from(files);
            setFormData((prevData) => ({
                ...prevData,
                productimage: fileList
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Assuming createData sends a POST request with formData to your API
        try {
            const response = await axios.post('https://oakyaecom.oakya.tech/tbl_sample_product.php', formData);
            console.log('Product created:', response.data);
            // Reset form after successful submission if needed
            setFormData({
                productname: '',
                brandname: '',
                category: '',
                productimage: [],
                description: '',
                price: 0,
                sellingprice: 0,
                createddate: '',
                updateddate: ''
            });
            alert('Product created successfully!');
        } catch (error) {
            console.error('Error creating product:', error);
            alert('Failed to create product.');
        }
    };

    return (
        <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-md">
            <h2 className="text-2xl font-semibold mb-4">Create Product</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="productname" className="block text-sm font-medium text-gray-700">Product Name</label>
                    <input type="text" id="productname" name="productname" value={formData.productname} onChange={handleChange} placeholder="Enter product name" required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="brandname" className="block text-sm font-medium text-gray-700">Brand Name</label>
                    <input type="text" id="brandname" name="brandname" value={formData.brandname} onChange={handleChange} placeholder="Enter brand name" required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category</label>
                    <input type="text" id="category" name="category" value={formData.category} onChange={handleChange} placeholder="Enter category" required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="productimage" className="block text-sm font-medium text-gray-700">Product Image</label>
                    <input type="file" id="productimage" name="productimage" onChange={handleChange} multiple required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea id="description" name="description" value={formData.description} onChange={handleChange} placeholder="Enter description" required rows="3" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
                </div>
                <div>
                    <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price</label>
                    <input type="number" id="price" name="price" value={formData.price} onChange={handleChange} placeholder="Enter price" required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="sellingprice" className="block text-sm font-medium text-gray-700">Selling Price</label>
                    <input type="number" id="sellingprice" name="sellingprice" value={formData.sellingprice} onChange={handleChange} placeholder="Enter selling price" required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="createddate" className="block text-sm font-medium text-gray-700">Create Date</label>
                    <input type="date" id="createddate" name="createddate" value={formData.createddate} onChange={handleChange} required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div>
                    <label htmlFor="updateddate" className="block text-sm font-medium text-gray-700">Update Date</label>
                    <input type="date" id="updateddate" name="updateddate" value={formData.updateddate} onChange={handleChange} required className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
                <div className="flex justify-end">
                    <button type="submit" className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        Create Product
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProductForm;
