// src/components/ProfilePopup.js
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import SummaryApi from '../common';

const ProfilePopup = ({  onClose }) => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA');
        const customerId = encodedCustomerId
        ? decodeURIComponent(atob(encodedCustomerId))
        : null;
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await fetch(SummaryApi.getCustomerDetails.url, {
          method: SummaryApi.getCustomerDetails.method,
          headers: {
            'Authorization': 'Bearer oakyaecom_units_916',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerid: customerId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch customer details');
        }

        const data = await response.json();
        setCustomerDetails(data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    if (customerId) {
      fetchCustomerDetails();
    }
  }, [customerId]);
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background Overlay with Blur Effect */}
      <div className="absolute inset-0 bg-black opacity-50 backdrop-blur-sm" />

      {/* Popup Container */}
      <div className="relative bg-white shadow-xl rounded-lg p-6 w-full max-w-4xl">
        <h2 className="text-2xl font-semibold mb-6">Profile Details</h2>
        <div className="space-y-4">
          <div className="flex">
            <span className="font-bold w-40 text-gray-700">Name:</span>
            <span className="text-gray-900">{customerDetails?.data?.full_name || 'N/A'}</span>
          </div>
          <div className="flex">
            <span className="font-bold w-40 text-gray-700">Email:</span>
            <span className="text-gray-900">{customerDetails?.data?.email || 'N/A'}</span>
          </div>
          <div className="flex">
            <span className="font-bold w-40 text-gray-700">Phone:</span>
            <span className="text-gray-900">{customerDetails?.data?.mobile_number || 'N/A'}</span>
          </div>
          <div className="flex">
            <span className="font-bold w-40 text-gray-700">Address:</span>
            <span className="text-gray-900">{customerDetails?.data?.address || 'N/A'}</span>
          </div>
        </div>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ProfilePopup;
