import React, { useEffect } from 'react';
import SummaryApi from '../common';
import displayINRCurrency from '../helpers/displayCurrency';

const OrderDetailPopup = ({ order, onClose }) => {
  useEffect(() => {
    // Disable background scroll
    document.body.style.overflow = 'hidden';

    // Re-enable scroll when popup is closed
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  if (!order) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1001]">
      <div className="bg-white lg:min-w-[900px] p-6 md:p-10 rounded-lg shadow-lg max-w-full md:max-w-6xl mx-4 md:mx-auto relative max-h-screen overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 text-2xl font-bold"
        >
          &times;
        </button>
        <h2 className="text-3xl font-bold mb-6 text-gray-900 border-b pb-3">Order Details</h2>
        <div className="space-y-8">
          {/* Order Summary Section */}
          <section className="bg-gray-50 p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold text-gray-800 mb-2 border-b pb-2">Order Summary</h3>
            
            <div className="space-y-2">
              {/* Order Information */}
              <div className='mb-2 border-b'>
                <h4 className="text-lg md:text-xl font-semibold text-gray-800 mb-2 mt-4">Order Information</h4>
                <div className="flex flex-col space-y-1">
                  <div className="flex">
                    <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Order Code:</span>
                    <span className="text-xs md:text-sm text-gray-700 flex-1">{order.order_code}</span>
                  </div>
                  <div className="flex">
                    <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Customer:</span>
                    <span className="text-xs md:text-sm text-gray-700 flex-1">{order.customer_name}</span>
                  </div>
                  <div className="flex">
                    <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Address:</span>
                    <span className="text-xs md:text-sm text-gray-700 flex-1">{order.order_address}</span>
                  </div>
                </div>
              </div>

              {/* Payment Details */}
              <div className='mb-2 border-b'>
                <h4 className="text-lg md:text-xl font-semibold text-gray-800 mb-2 mt-4">Payment Details</h4>
                <div className="flex flex-col space-y-1">
                  <div className="flex">
                    <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Order Amount:</span>
                    <span className="text-xs md:text-sm text-gray-700 flex-1">{displayINRCurrency(order.order_amount)}</span>
                  </div>
                  <div className="flex">
                    <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Delivery Charges:</span>
                    <span className="text-xs md:text-sm text-gray-700 flex-1">{displayINRCurrency(order.delivery_charges)}</span>
                  </div>
                  <div className="flex">
                    <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Total Amount:</span>
                    <span className="text-xs md:text-sm text-gray-700 flex-1">{displayINRCurrency(order.total_amount)}</span>
                  </div>
                  <div className="flex">
                    <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Payment Mode:</span>
                    <span className="text-xs md:text-sm text-gray-700 flex-1">{order.payment_mode}</span>
                  </div>
                </div>
              </div>

              {/* Order Status */}
              <div className='mb-2 border-b'>
                <h4 className="text-lg md:text-xl font-semibold text-gray-800 mb-2 mt-4">Order Status</h4>
                <div className="flex">
                  <span className="text-xs md:text-sm text-gray-700 font-bold w-32">Status:</span>
                  <span className="text-xs md:text-sm text-gray-700 flex-1">{order.order_status}</span>
                </div>
              </div>
            </div>
          </section>

          {/* Order Items Section */}
          <section>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4 border-b pb-2">Order Items</h3>
            {order.order_items.length > 0 ? (
              order.order_items.map((item) => (
                <div
                  key={item.order_items_id}
                  className="flex flex-col md:flex-row items-start md:items-center p-4 bg-white rounded-lg shadow-sm mb-4 relative"
                >
                  <div className="flex items-center flex-1">
                    <img
                      src={SummaryApi.productUnitImage.url + item.product_img}
                      alt={item.product_name}
                      className="h-24 w-24 object-contain mb-4 md:mb-0 mr-4 rounded-lg"
                    />
                    <div className="flex flex-col flex-1">
                      <h4 className="text-lg font-semibold text-gray-800">{item.product_name}({item.unit_type})</h4>
                      <p className="text-sm text-gray-600">{displayINRCurrency(item.price)} x {item.quantity}</p>
                    </div>
                  </div>
                  <p className="text-lg font-semibold absolute bottom-0 right-4 md:static md:bottom-auto md:right-auto">
                    {displayINRCurrency(item.price * item.quantity)}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray-600">No items found</p>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailPopup;
