import React, { useEffect, useState } from 'react';
import AdminCategoryCard from '../components/AdminCategoryCard';
import UploadCategory from '../components/UploadCategory';
import ReorderPopup from '../components/ReorderPopup'; // Import the updated popup component
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const AllCategory = () => {
  const [openUploadCategory, setOpenUploadCategory] = useState(false);
  const [openReorderPopup, setOpenReorderPopup] = useState(false); // State for reorder popup
  const [allCategory, setAllCategory] = useState([]);

  const fetchAllCategory = async () => {
    try {
      const response = await fetch(SummaryApi.adminCategory.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }

      const dataResponse = await response.json();
      const reversedCategories = (dataResponse?.data || []).reverse();
      setAllCategory(reversedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error(`Failed to fetch categories: ${error.message}`);
    }
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);

  return (
    <div>
      <div className='bg-white py-2 px-4 flex flex-col sm:flex-row justify-between items-center'>
        <h2 className='font-bold text-lg'>All Categories</h2>
        <div className='flex'>
          <div className='flex items-center mx-2 text-sm '>
            <div>Active</div> 
            <div className='mx-2 text-lg text-[#008000]'><FaCheckCircle/></div> 
            <div className='ml-2'>Inactive</div> 
            <div className='mx-2 text-lg text-[#D22B2B]'><FaTimesCircle /></div>
          </div>
          <button
            className='border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-all py-1 px-3 rounded-full'
            onClick={() => setOpenUploadCategory(true)}
          >
            Upload Category
          </button>
          <button
            className='ml-2 border-2 border-gray-600 text-gray-600 hover:bg-gray-600 hover:text-white transition-all py-1 px-3 rounded-full'
            onClick={() => setOpenReorderPopup(true)} // Show reorder popup
          >
            Category Positions
          </button>
        </div>
      </div>

      <div className='flex items-center flex-wrap gap-5 py-4 overflow-y-scroll'>
        {allCategory.map((product, index) => (
          <AdminCategoryCard key={index} data={product} fetchdata={fetchAllCategory} />
        ))}
      </div>

      {openUploadCategory && <UploadCategory onClose={() => setOpenUploadCategory(false)} fetchdata={fetchAllCategory} />}
      {openReorderPopup && <ReorderPopup onClose={() => setOpenReorderPopup(false)} fetchdata={fetchAllCategory} />}
    </div>
  );
};

export default AllCategory;
