import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { toast } from 'react-toastify'; // Assuming you're using react-toastify for notifications
import SummaryApi from '../common';
import { useNavigate } from 'react-router-dom';

const BannerProduct = () => {
    const [desktopImages, setDesktopImages] = useState([]);
    const [mobileImages, setMobileImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBannerImages = async () => {
            setLoading(true);
            try {
                const response = await fetch(SummaryApi.getBannerImages.url, {
                    method: SummaryApi.getBannerImages.method,
                    headers: {
                        'Authorization': 'Bearer oakyaecom_units_916',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to retrieve banners');
                }

                const responseData = await response.json();

                if (responseData.status) {
                    // Extract desktop and mobile images from the response data
                    const desktopImgs = responseData.data.map(banner => banner);
                    const mobileImgs = responseData.data.map(banner => banner);

                    setDesktopImages(desktopImgs);
                    setMobileImages(mobileImgs);
                } else {
                    toast.error(responseData.message);
                }
            } catch (error) {
                toast.error('Error retrieving banners');
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchBannerImages();
    }, []);

    const encodeBannerId = (id) => {
        if (!id) return '';
        return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
      };

    const handleImageClick = (bannerId) => {
        navigate(`/banner/${encodeBannerId(bannerId)}`); // Navigate to BannerProductScreen with encoded ID
    };
    // Render loading spinner or banners
    return loading ? (
        <div className='flex flex-col items-center justify-center min-h-36 md:min-h-72'>
            <div className='border-t-4 border-blue-500 border-solid rounded-full w-12 h-12 animate-spin mb-4'></div>
            <p>Loading...</p>
        </div>
    ) : (
        <div className="container mx-auto px-4 rounded">
            <div className='h-full w-full bg-slate-200 relative'>
                {/* Desktop view */}
                <div className="hidden md:flex h-full w-full overflow-hidden">
                    <Swiper
                        loop
                        spaceBetween={0}
                        navigation={true}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Autoplay, Pagination]}
                        autoplay={{ delay: 4500 }}
                        className="h-full"
                    >
                        {desktopImages.map((imageUrl, index) => (
                            <SwiperSlide key={index} onClick={() => handleImageClick(imageUrl.banner_id)}>
                                <div className='w-full h-full min-w-full min-h-full transition-all'>
                                    <img src={SummaryApi.bannerImage.url + imageUrl.desktop_img} className='w-full h-72 object-cover cursor-pointer' alt={`Desktop Banner ${index}`} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/* Mobile View */}
                <div className="flex h-full w-full overflow-hidden md:hidden">
                    <Swiper
                        loop
                        spaceBetween={0}
                        pagination={{ clickable: true }}
                        modules={[Autoplay, Pagination]}
                        autoplay={{ delay: 4500 }}
                        className="h-full"
                    >
                        {mobileImages.map((imageUrl, index) => (
                            <SwiperSlide key={index} onClick={() => handleImageClick(imageUrl.banner_id)}>
                                <div className='w-full h-full min-w-full min-h-full transition-all'>
                                    <img src={SummaryApi.bannerImage.url + imageUrl.mobile_img} className='w-full h-full min-h-36 object-contain' alt={`Mobile Banner ${index}`} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default BannerProduct;
