import React, { useEffect, useState } from 'react';
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import DisplayImage from './DisplayImage'; 
import { MdDelete } from "react-icons/md";
import { toast } from 'react-toastify';
import Switch from "react-switch"; 
import SummaryApi from '../common'

const UploadSubCategory = ({
    onClose,
    fetchdata
}) => {
    const [data, setData] = useState({
        subcategoryid: '',
        categoryid: '',
        subcategoryname: '',
        image: '',
        imageUrl: '',
        isactive: true,
    });
    const [loading, setLoading] = useState(false); 
    const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState('');

    const [allCategory, setAllCategory] = useState([]);

    const fetchAllCategory = async () => {
        const response = await fetch(SummaryApi.allCategory.url, {
            headers: {
                'Authorization': 'Bearer oakyaecom_units_916',
                'Content-Type': 'application/json',
            },
        });
        const dataResponse = await response.json();
        setAllCategory(dataResponse?.data || []);
    };

    useEffect(() => {
        fetchAllCategory();
    }, []);
    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleUploadSubCategoryImage = async (e) => {
        const file = e.target.files[0]; 
        if (file) {
            setData((prev) => ({
                ...prev,
                image: file, 
                imageUrl: URL.createObjectURL(file) 
            }));
        }
    };

    const handleDeleteSubCategoryImage = () => {
        setData((prev) => ({
            ...prev,
            image: '',
            imageUrl: ''
        }));
    };

    const handleActiveToggle = (checked) => {
        setData((prev) => ({
            ...prev,
            isactive: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); 
        if (data.image === "") {
            toast.error('Image upload required');
            setLoading(false); 
            return;
        }
        try {
            const formData = new FormData();
            formData.append('subcategoryid', data.subcategoryid);
            formData.append('categoryid', data.categoryid);
            formData.append('subcategoryname', data.subcategoryname);
            formData.append('image', data.image);
            formData.append('isactive', data.isactive ? 1 : 0);
            const response = await fetch(SummaryApi.uploadSubCategory.url, {
                method: SummaryApi.uploadSubCategory.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                },
                body: formData,
            });
            console.log("1", data)
            if (!response.ok) {
                throw new Error('Failed to update Sub Category');
            }

            const responseData = await response.json();

            if (responseData.status) {
                toast.success(responseData.message);
                onClose();
                fetchdata();
            } else {
                toast.error(responseData.message);
            }
        } catch (error) {
            toast.error('Error updating Sub Category');
            console.error('Error:', error);
        } finally {
            setLoading(false); 
        }
    };
    return (
        <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center z-10'>
            <div className='bg-white p-4 rounded w-full max-w-2xl max-h-[80%] overflow-y-auto'>
                <div className='flex justify-between items-center pb-3'>
                    <h2 className='font-bold text-lg'>Upload Sub Category</h2>
                    <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
                        <CgClose />
                    </div>
                </div>
                <form className='grid p-4 gap-2 h-full pb-5' onSubmit={handleSubmit}>
                    <label htmlFor='categoryid' className='block'>
                        Category :
                    </label>
                    <select
                        required
                        value={data.categoryid}
                        name='categoryid'
                        onChange={handleOnChange}
                        className='p-2 h-10 bg-slate-100 border rounded w-full'>
                        <option value={''}>Select Category</option>
                        {allCategory.map((el, index) => (
                            <option value={el.category_id} key={el.category_name + index}>
                                {el.category_name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor='subcategoryname'>Sub Category Name:</label>
                    <input
                        type='text'
                        id='subCategory'
                        placeholder='Enter sub category name'
                        name='subcategoryname'
                        value={data.subcategoryname}
                        onChange={handleOnChange}
                        className='p-2 h-10 bg-slate-100 border rounded'
                        required
                    />
                    <label htmlFor='image' className='mt-3'>
                        Sub Category Image:
                    </label>
                    <label htmlFor='uploadSubCategoryImageInput'>
                        <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer'>
                            <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                                <span className='text-4xl'>
                                    <FaCloudUploadAlt />
                                </span>
                                <p className='text-sm'>Upload Sub Category Image</p>
                                <input
                                    type='file'
                                    id='uploadSubCategoryImageInput'
                                    className='hidden'
                                    onChange={handleUploadSubCategoryImage}
                                />
                            </div>
                        </div>
                    </label>
                    {data.imageUrl && (
                        <div className='flex items-center gap-2 mt-3'>
                            <div className='relative group'>
                                <img
                                    src={data.imageUrl}
                                    alt='Uploaded Image'
                                    className='bg-slate-100 border cursor-pointer w-28'
                                    onClick={() => {
                                        setOpenFullScreenImage(true);
                                        setFullScreenImage(data.imageUrl);
                                    }}
                                />
                                <div
                                    className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer'
                                    onClick={handleDeleteSubCategoryImage}
                                >
                                    <MdDelete />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='flex items-center mt-3'>
                        <Switch
                            onChange={handleActiveToggle}
                            checked={data.isactive == 1}
                            height={24}
                            width={78}
                            handleDiameter={20}
                            handleStyle={{
                                backgroundColor: '#4caf50',
                                boxShadow: '0 0 2px 3px rgba(0,0,0,0.1)',
                            }}
                            onColor='#008000'
                            offColor='#D22B2B'
                            checkedIcon={<div className='flex justify-center items-center h-full text-sm ml-3 text-white'>Active</div>}
                            uncheckedIcon={<div className='flex justify-center items-center h-full text-sm mr-5 text-white'>Inactive</div>}
                            className='mt-2'
                        />
                    </div>
                    <button className='px-3 py-2 bg-red-600 text-white mt-5 hover:bg-red-700'>
                        Upload Sub Category
                    </button>
                </form>
                {loading && (
                    <div className='absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center'>
                        <div className='text-lg font-bold'>Uploading Sub Category...</div>
                    </div>
                )}
                {/* Display full screen image modal */}
                {openFullScreenImage && (
                    <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={fullScreenImage} />
                )}
            </div>
        </div>
    );
};

export default UploadSubCategory;
