import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Logo1 from '../assest/Ecom10.jpg';
import { GrSearch } from 'react-icons/gr';
import { FaShoppingCart } from 'react-icons/fa';
import Context from '../context';
import scrollTop from '../helpers/scrollTop';
import SummaryApi from '../common';
import ProfilePopup from '../pages/ProfilePopup';

const Header = () => {
  const [menuDisplay, setMenuDisplay] = useState(false);
  const [profilePopupVisible, setProfilePopupVisible] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const context = useContext(Context);
  const navigate = useNavigate();
  const searchInput = useLocation();
  const URLSearch = new URLSearchParams(searchInput?.search);
  const searchQuery = URLSearch.getAll('q');
  const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA');
  const customerId = encodedCustomerId
    ? decodeURIComponent(atob(encodedCustomerId))
    : null;

  const menuRef = useRef(null);
  const searchInputRef = useRef(null);

  // Helper function to encode product_id
  const encodeProductId = (id) => {
    if (!id) return '';
    return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
  };

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await fetch(SummaryApi.getCustomerDetails.url, {
          method: SummaryApi.getCustomerDetails.method,
          headers: {
            'Authorization': 'Bearer oakyaecom_units_916',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ customerid: customerId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch customer details');
        }

        const data = await response.json();
        setCustomerDetails(data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    if (customerId) {
      fetchCustomerDetails();
    }
  }, [customerId]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (search.length > 0) {
        try {
          const response = await fetch(SummaryApi.searchProduct.url, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer oakyaecom_units_916',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ searchterm: search }),
          });

          if (!response.ok) {
            throw new Error('Failed to fetch search results');
          }

          const data = await response.json();
          setSearchResults(data.data); // Adjust based on your API response structure
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [search]);

  const handleLogout = () => {
    Cookies.remove('M8V5R1JQ3K0PWT6Z9LXA');
    toast.success('Logged out successfully');
    navigate('/');
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setIsDropdownVisible(value.length > 0);
  };

  const handleSearchResultClick = (id) => {
    navigate(`/product/${encodeProductId(id)}`);
    setIsDropdownVisible(false);
    setSearch('');
  };

  const toggleProfilePopup = () => {
    setProfilePopupVisible((prev) => !prev);
    setMenuDisplay(false); // Ensure the menu is closed when opening the profile popup
  };

  const toggleMenu = () => {
    setMenuDisplay((prev) => !prev);
  };

  return (
    <header className="h-auto md:h-16 lg:h-20 shadow-md bg-white sticky top-0 left-0 right-0 z-50">
      {/* Desktop View */}
      <div className="hidden md:flex container items-center justify-between h-full mx-auto">
        {/* Logo and Brand Name */}
        <div>
          <Link to={'/'} className="items-center flex flex-col" onClick={scrollTop}>
            <div>
              <img src={Logo1} className="h-16 mx-auto" alt="Logo" />
            </div>
          </Link>
        </div>

        {/* Search Bar */}
        <div className="relative flex items-center w-full max-w-lg border rounded-md focus-within:shadow pl-2">
          <input
            type="text"
            placeholder="search product here..."
            className="w-full outline-none"
            onChange={handleSearch}
            value={search}
            ref={searchInputRef}
          />
          <div className="text-lg min-w-[50px] h-10 bg-red-600 flex items-center justify-center rounded-r-md text-white">
            <GrSearch />
          </div>
          {isDropdownVisible && searchResults.length > 0 && (
            <div className="absolute top-full left-0 right-0 bg-white shadow-lg rounded-b-md mt-1 z-10">
              <ul className="max-h-72 overflow-y-auto">
                {searchResults.map(result => (
                  <li
                    key={result.product_id}
                    className="flex items-center cursor-pointer p-2 hover:bg-gray-100"
                    onClick={() => handleSearchResultClick(result.product_id)}
                  >
                    <img src={SummaryApi.productUnitImage.url + result.unit_product_img1} alt='image' className='w-10 h-10 object-contain mr-2 '/>
                    <div className='flex flex-1 items-center justify-between'>
                      <span className='line-clamp-1'>{result.product_name}</span>
                      <GrSearch />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* User Menu */}
        <div className="flex items-center gap-7">
          {customerId && (
            <div
              className="relative"
            >
              <div
                className="text-md cursor-pointer capitalize"
                onClick={toggleMenu}
              >
                Hi, {customerDetails?.data?.full_name}
              </div>

              {menuDisplay && (
                <div
                  ref={menuRef}
                  className="absolute bg-white shadow-lg rounded mt-2 p-2 w-48"
                  onMouseEnter={() => setMenuDisplay(true)}
                  onMouseLeave={() => setMenuDisplay(false)}
                >
                  <nav className="flex flex-col text-sm">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        toggleProfilePopup();
                      }}
                      className="whitespace-nowrap p-2 hover:bg-slate-100 text-left w-full text-gray-800"
                    >
                      Profile
                    </button>
                    <Link
                      to={'/my-orders'}
                      className="whitespace-nowrap p-2 hover:bg-slate-100 text-left w-full text-gray-800"
                      onClick={() => setMenuDisplay(false)}
                    >
                      Orders
                    </Link>
                  </nav>
                </div>
              )}

              {profilePopupVisible && (
                <ProfilePopup onClose={() => setProfilePopupVisible(false)} />
              )}
            </div>
          )}

          {/* Cart Icon */}
          {customerId && (
            <Link to={'/cart'} className="text-3xl cursor-pointer relative" onClick={scrollTop}>
              <FaShoppingCart />
              <div className="bg-red-600 text-white w-4 h-4 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3">
                <p className="text-sm">{context?.cartProductCount}</p>
              </div>
            </Link>
          )}

          {/* Login/Logout Button */}
          <div>
            {customerId ? (
              <button
                onClick={handleLogout}
                className="px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700"
              >
                Logout
              </button>
            ) : (
              <Link
                to={'/login'}
                className="px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* Mobile view */}
      <div className="md:hidden container items-center justify-between h-full pb-1 mx-auto px-4">
        <div className="flex items-center justify-between w-full">
          <div>
            <Link to={'/'} className="items-center flex flex-col mt-1">
              <div>
                <img src={Logo1} className="h-14 mx-auto my-[2px]" alt="Logo" />
              </div>
            </Link>
          </div>

          <div className="flex items-center gap-7">
            {customerId && (
              <div
                className="relative"
              >
                <div
                  className="text-md cursor-pointer"
                  onClick={toggleMenu}
                >
                  Hi, {customerDetails?.data?.full_name}
                </div>

                {menuDisplay && (
                  <div
                    className="absolute bg-white shadow-lg rounded mt-2 p-2 w-48 z-20"
                    onMouseEnter={() => setMenuDisplay(true)}
                    onMouseLeave={() => setMenuDisplay(false)}
                  >
                    <nav className="flex flex-col text-xs">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          toggleProfilePopup();
                        }}
                        className="whitespace-nowrap p-2 hover:bg-slate-100 text-left w-full text-gray-800"
                      >
                        Profile
                      </button>
                      <Link
                        to={'/my-orders'}
                        className="whitespace-nowrap p-2 hover:bg-slate-100 text-left w-full text-gray-800"
                        onClick={() => setMenuDisplay(false)}
                      >
                        Orders
                      </Link>
                    </nav>
                  </div>
                )}

                {profilePopupVisible && (
                  <ProfilePopup onClose={() => setProfilePopupVisible(false)} />
                )}
              </div>
            )}

            {customerId && (
              <Link to={'/cart'} className="text-3xl cursor-pointer relative" onClick={scrollTop}>
                <FaShoppingCart />
                <div className="bg-red-600 text-white w-4 h-4 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3">
                  <p className="text-sm">{context?.cartProductCount}</p>
                </div>
              </Link>
            )}

            <div>
              {customerId ? (
                <button
                  onClick={handleLogout}
                  className="px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700"
                >
                  Logout
                </button>
              ) : (
                <Link
                  to={'/login'}
                  className="px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-700"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="relative flex items-center w-full max-w-lg border rounded-md focus-within:shadow m-2 pl-2 transition-opacity duration-500 opacity-100">
          <input
            type="text"
            placeholder="search product here..."
            className="w-full outline-none"
            onChange={handleSearch}
            value={search}
            ref={searchInputRef}
          />
          <div className="text-lg min-w-[50px] h-10 bg-red-600 flex items-center justify-center rounded-r-md text-white">
            <GrSearch />
          </div>
          {isDropdownVisible && searchResults.length > 0 && (
            <div className="absolute top-full left-0 right-0 bg-white shadow-lg rounded-b-md mt-1 z-10">
              <ul className="max-h-72 overflow-y-auto">
                {searchResults.map(result => (
                  <li
                    key={result.product_id}
                    className="flex items-center cursor-pointer p-2 hover:bg-gray-100"
                    onClick={() => handleSearchResultClick(result.product_id)}
                  >
                    <img src={SummaryApi.productUnitImage.url + result.unit_product_img1} alt='image' className='w-10 h-10 object-contain mr-2 '/>
                    <div className='flex flex-1 items-center justify-between'>
                      <span className='line-clamp-1'>{result.product_name}</span>
                      <GrSearch />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
