import React, { useContext, useEffect, useState, useRef } from 'react';
import fetchCategoryWiseProduct from '../helpers/fetchCategoryWiseProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import addToCart from '../helpers/addToCart';
import Context from '../context';
import scrollTop from '../helpers/scrollTop';
import SummaryApi from '../common';
import { toast } from 'react-toastify';

const CategoryWiseProductDisplay = ({ category, heading }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadingList = new Array(13).fill(null);
  const { fetchUserAddToCart } = useContext(Context);
  const navigate = useNavigate();

  // Helper function to encode product_id
  const encodeProductId = (id) => {
    if (!id) return '';
    return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
  };

  const scrollContainerRef = useRef(null);

  const handleAddToCart = async (e, id, unit_id, name) => {
    const response = await addToCart(e, id, unit_id)
    if (response.status) {
      toast.success(`${name} added to cart`)
      fetchUserAddToCart()
    } else {
      navigate('/login')
      toast.error(response.message)
    }

  }

  const fetchData = async () => {
    setLoading(true);
    const categoryProduct = await fetchCategoryWiseProduct(category);
    setLoading(false);
    setData(categoryProduct?.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -320, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 320, behavior: 'smooth' });
    }
  };

  return (
    <div className='container mx-auto px-4 my-6 relative'>
      <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

      <div className='md:flex items-center gap-6'>
        <button onClick={scrollLeft} className='p-2'>
          <FaAngleLeft className='hidden md:block absolute left-0 text-2xl text-gray-600 hover:text-gray-900 transition-colors' />
        </button>
        <div ref={scrollContainerRef} className='flex gap-6 overflow-x-scroll scrollbar-none transition-all'>
          {loading ? (
            loadingList.map((_, index) => (
              <div
                key={index}
                className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'
              >
                <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center animate-pulse'></div>
                <div className='p-4 grid gap-3'>
                  <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black p-1 py-2 animate-pulse rounded-full bg-slate-200'></h2>
                  <p className='capitalize text-slate-500 p-1 animate-pulse rounded-full bg-slate-200 py-2'></p>
                  <div className='flex gap-3'>
                    <p className='text-red-600 font-medium p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                    <p className='text-slate-500 line-through p-1 animate-pulse rounded-full bg-slate-200 w-full py-2'></p>
                  </div>
                  <button className='text-sm text-white px-3 rounded-full bg-slate-200 py-2 animate-pulse'></button>
                </div>
              </div>
            ))
          ) : (
            data.map((product, index) => (
              <Link
                key={index}
                to={`/product/${encodeProductId(product?.product_id)}`}
                className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow transition-transform duration-300 hover:scale-105'
                onClick={scrollTop}
              >
                <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                  <img
                    src={`${SummaryApi.productUnitImage.url}${product.unit_product_img1}`}
                    className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
                  />
                </div>
                <div className='p-4 grid gap-1'>
                  <h2 className='font-medium text-base md:text-lg text-ellipsis line-clamp-1 text-black'>
                    {product?.product_name}
                  </h2>
                  <div className='flex gap-3'>
                    <p className='text-red-600 font-medium'>
                      {displayINRCurrency(product?.after_discount_price)}
                    </p>
                    <p className='text-slate-500 line-through'>
                      {displayINRCurrency(product?.price)}
                    </p>
                  </div>
                  <button
                    className='text-sm bg-red-600 hover:bg-red-700 text-white px-3 py-0.5 rounded-full'
                    onClick={(e) => handleAddToCart(e, product?.product_id, product?.product_unit_id, product?.product_name)}
                  >
                    Add to Cart
                  </button>
                </div>
              </Link>
            ))
          )}
        </div>
        <button onClick={scrollRight} className='p-2'>
          <FaAngleRight className='hidden md:block absolute right-0 text-2xl text-gray-600 hover:text-gray-900 transition-colors' />
        </button>
      </div>
    </div>
  );
};

export default CategoryWiseProductDisplay;
