import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SummaryApi from '../common';

const ChangePassword = () => {
    const [data, setData] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const navigate = useNavigate();

    // Function to handle input changes
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate passwords
        if (data.newPassword !== data.confirmPassword) {
            toast.error("New passwords do not match.");
            return;
        }
        if (data.oldPassword === data.newPassword) {
            toast.error("New password cannot be the same as the old password.");
            return;
        }

        // Retrieve admin ID from sessionStorage
        const adminid = atob(sessionStorage.getItem('H7Z4L9VD2XK5JW8QRYT6'));
        // Verify old password
        try {
            const verifyResponse = await fetch(SummaryApi.verifyOldPassword.url, {
                method: SummaryApi.verifyOldPassword.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "adminid":adminid,
                    "password": data.oldPassword
                })
            });

            const verifyResult = await verifyResponse.json();
            console.log(verifyResult)

            if (verifyResult.status) {
                // Old password verified, now change the password
                try {
                    const changeResponse = await fetch(SummaryApi.updateNewPassword.url, {
                        method: SummaryApi.updateNewPassword.method,
                        headers: {
                            'Authorization': 'Bearer oakyaecom_units_916',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "adminid":adminid,
                            "password": data.newPassword
                        })
                    });

                    const changeResult = await changeResponse.json();
                    console.log(changeResult)
                    if (changeResult.status) {
                        toast.success("Password updated. Logged out for security.");
                        sessionStorage.removeItem('H7Z4L9VD2XK5JW8QRYT6'); // Remove the 'adminid' from sessionStorage
                        navigate('/admin-login');
                    } else {
                        toast.error("Failed to change password: " + changeResult.message);
                    }
                } catch (error) {
                    toast.error("Error changing password: " + error.message);
                }
            } else {
                toast.error("Old password is incorrect.");
            }
        } catch (error) {
            toast.error("Error verifying old password: " + error.message);
        }
    };

    return (
        <section id='change-password' className='flex items-start justify-center p-4'>
        <div className='bg-white p-8 rounded-lg shadow-lg w-full max-w-md'>
            <h2 className='text-2xl font-bold text-gray-800 mb-6 text-center'>Change Password</h2>
            <form className='space-y-4' onSubmit={handleSubmit}>
                <div className='flex flex-col'>
                    <label className='text-gray-700 mb-2 font-medium'>Old Password:</label>
                    <div className='relative'>
                        <input
                            type={showOldPassword ? "text" : "password"}
                            placeholder='Enter old password'
                            name='oldPassword'
                            value={data.oldPassword}
                            onChange={handleOnChange}
                            className='w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500'
                        />
                        <div className='absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer' onClick={() => setShowOldPassword(prev => !prev)}>
                            {showOldPassword ? <FaEyeSlash className='text-gray-500' /> : <FaEye className='text-gray-500' />}
                        </div>
                    </div>
                </div>

                <div className='flex flex-col'>
                    <label className='text-gray-700 mb-2 font-medium'>New Password:</label>
                    <div className='relative'>
                        <input
                            type={showNewPassword ? "text" : "password"}
                            placeholder='Enter new password'
                            name='newPassword'
                            value={data.newPassword}
                            onChange={handleOnChange}
                            className='w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500'
                        />
                        <div className='absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer' onClick={() => setShowNewPassword(prev => !prev)}>
                            {showNewPassword ? <FaEyeSlash className='text-gray-500' /> : <FaEye className='text-gray-500' />}
                        </div>
                    </div>
                </div>

                <div className='flex flex-col'>
                    <label className='text-gray-700 mb-2 font-medium'>Confirm New Password:</label>
                    <div className='relative'>
                        <input
                            type={showNewPassword ? "text" : "password"}
                            placeholder='Confirm new password'
                            name='confirmPassword'
                            value={data.confirmPassword}
                            onChange={handleOnChange}
                            className='w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-red-500'
                        />
                        <div className='absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer' onClick={() => setShowNewPassword(prev => !prev)}>
                            {showNewPassword ? <FaEyeSlash className='text-gray-500' /> : <FaEye className='text-gray-500' />}
                        </div>
                    </div>
                </div>

                <button type='submit' className='bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded-full font-semibold text-lg w-full transition-transform transform hover:scale-105'>
                    Change Password
                </button>
            </form>
        </div>
    </section>
    );
}

export default ChangePassword;
