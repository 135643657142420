import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SummaryApi from '../common'; // Ensure this path is correct
import displayINRCurrency from '../helpers/displayCurrency';

const BannerProductScreen = () => {
  const [data, setData] = useState([]);
  const [bannerImages, setBannerImages] = useState({});
  const [loading, setLoading] = useState(true);
  const params = useParams();

  // Helper function to encode banner_id
  const encodeBannerId = (id) => {
    if (!id) return '';
    return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
  };

  // Function to decode product_id
  const decodeProductId = (encodedId) => {
    if (!encodedId) return '';   // Decode the Base64 encoded string
    const decoded = atob(encodedId);   // Decode percent-encoded characters
    return decodeURIComponent(decoded);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(SummaryApi.getBannerProduct.url, {
        method: SummaryApi.getBannerProduct.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bannerid: decodeProductId(params?.id)
        })
      });
      const dataResponse = await response.json();
      const reversedData = (dataResponse?.products || []).reverse();
      setBannerImages(dataResponse?.banner_images || {});
      setData(reversedData || []);
    } catch (error) {
      console.error("Error fetching banner products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    const loadingItems = new Array(12).fill(null);
    return (
      <div className="container mx-auto px-4 pt-2 md:pt-4">
        {/* Placeholder for banner images */}
        <div className="mb-4">
          <div className="hidden md:block mb-4 bg-gray-200 animate-pulse h-64 w-full rounded-lg"></div>
          <div className="md:hidden mb-4 bg-gray-200 animate-pulse h-48 w-full rounded-lg"></div>
        </div>

        <h2 className="text-xl font-bold md:mb-4">Loading Banners...</h2>

        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {loadingItems.map((_, index) => (
            <div key={`placeholder-${index}`} className="bg-white rounded-lg border shadow-lg overflow-hidden">
              <div className="h-32 md:h-52 animate-pulse flex justify-center items-center bg-gray-200">
                <div className="h-24 md:h-44 bg-gray-300 rounded-lg"></div>
              </div>
              <div className="p-4 bg-gray-100">
                <div className="w-3/4 h-[14px] bg-gray-300 mb-2 animate-pulse rounded-full"></div>
                <div className="w-full h-[12px] bg-gray-300 mb-2 animate-pulse rounded-full"></div>
                <div className="w-1/2 h-[12px] bg-gray-300 mb-2 animate-pulse rounded-full"></div>
                <div className="w-2/3 h-[12px] bg-gray-300 mb-2 animate-pulse rounded-full"></div>
                <div className="w-1/3 h-[12px] bg-gray-300 mb-2 animate-pulse rounded-full"></div>
                <div className="w-1/2 h-8 bg-gray-300 animate-pulse rounded-full"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (!data.length) {
    return (
      <div className="container mx-auto p-4 text-center">
        <p>No banners available at the moment.</p>
      </div>
    );
  }

  const renderStars = (rating) => {
    const stars = Array(5).fill(false).map((_, index) => index < rating ? '★' : '☆');
    return stars.join(' ');
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleAddToCart = (e, productId, productName) => {
    e.stopPropagation();
    // Add your add to cart logic here
  };

  return (
    <div className="container mx-auto px-4 pt-2 md:pt-4 pb-4">
      {/* Banner Images */}
      <div className="hidden md:block mb-4">
        <img
          src={SummaryApi.bannerImage.url + bannerImages.desktop_img}
          alt="Desktop Banner"
          className="w-full h-64 object-cover rounded-lg"
        />
      </div>

      <div className="md:hidden mb-4">
        <img
          src={SummaryApi.bannerImage.url + bannerImages.mobile_img}
          alt="Mobile Banner"
          className="w-full h-48 object-contain rounded-lg"
        />
      </div>

      <h2 className="text-xl font-bold md:mb-4">Featured Banners</h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {data.map((product, index) => (
          <Link
            key={index}
            to={`/product/${encodeBannerId(product?.product_id)}`}
            className="bg-white rounded-lg border shadow-lg overflow-hidden"
            onClick={scrollTop}
          >
            <div className="h-32 md:h-52 flex justify-center items-center">
              <img
                src={`${SummaryApi.productUnitImage.url}${product?.unit_product_img1}`}
                className="object-cover h-24 md:h-44 transition-transform transform hover:scale-105"
                alt={product?.product_name}
              />
            </div>
            <div className="p-4 bg-gray-100">
              <h2 className="text-sm md:text-base text-ellipsis line-clamp-1 text-black mb-2">
                {product?.product_name}
              </h2>
              <p className="capitalize text-gray-600 mb-2">{product?.category?.category_name}</p>
              <div className="text-orange-500 flex items-center gap-1 mb-2">
                {renderStars(product?.rating)}
              </div>
              <div className="flex gap-3 mb-2">
                <p className="text-gray-500 text-sm md:text-base line-through">{displayINRCurrency(product?.price)}</p>
                <p className="text-black text-sm md:text-base font-medium">{displayINRCurrency(product?.after_discount_price)}</p>
                <p className="hidden md:block text-green-700 text-sm md:text-base font-medium">
                  {Math.round(((product?.price - product?.after_discount_price) / product?.price) * 100)}% off
                </p>
              </div>
              <button
                className="text-sm h-7 md:h-10 bg-red-600 hover:bg-red-700 text-white px-3 py-1 rounded-lg"
                onClick={(e) => handleAddToCart(e, product?.product_id, product?.product_name)}
              >
                Add to Cart
              </button>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BannerProductScreen;
