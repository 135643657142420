import React, { useState, useEffect, useRef } from 'react';
import displayINRCurrency from '../helpers/displayCurrency';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import SummaryApi from '../common';
import { Link } from 'react-router-dom';
import scrollTop from '../helpers/scrollTop';

const HorizontalCardProduct = ({ heading }) => {
    const [loading, setLoading] = useState(true);
    const loadingList = new Array(13).fill(null); // Placeholder array for loading state

    const scrollElement = useRef();

    const [allBestSeller, setAllBestSeller] = useState([])
    // Helper function to encode product_id
    const encodeProductId = (id) => {
        if (!id) return '';
        return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
    };

    const fetchAllBestSeller = async () => {
        setLoading(true);
        try {
            const response = await fetch(SummaryApi.getBestSeller.url, {
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                    'Content-Type': 'application/json',
                },
            });
            const dataResponse = await response.json();
            const reversedData = (dataResponse?.data || []).reverse();
            setAllBestSeller(reversedData || []);
        } catch (error) {
            console.error('Error fetching best sellers:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllBestSeller()
    }, [])

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300; // Scroll container right
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300; // Scroll container left
    };

    return (
        <div className='container mx-auto px-4 pt-2 md:pt-4 relative'>
            <h2 className='text-xl font-bold md:mb-2'>{heading}</h2>

            <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none transition-all' ref={scrollElement}>
                {/* Scroll buttons */}
                <button className='bg-white shadow-md rounded-full p-1 absolute -left-5 text-lg hidden md:block' onClick={scrollLeft}>
                    <FaAngleLeft />
                </button>
                <button className='bg-white shadow-md rounded-full p-1 absolute -right-5 text-lg hidden md:block' onClick={scrollRight}>
                    <FaAngleRight />
                </button>

                {/* Render products or placeholders based on loading state */}
                {loading ? (
                    loadingList.map((_, index) => (
                        <div key={index} className='w-full min-w-[144] max-w-[144px] md:min-w-[208] md:max-w-[208px] h-auto flex flex-col justify-center items-center bg-white rounded-sm border'>
                            {/* Placeholder image */}
                            <div className='h-36 w-36 md:h-52 md:w-52 p-3 min-w-[150] bg-gray-200 animate-pulse'></div>
                            <div className='w-full'>
                                {/* Placeholder product name */}
                                <h2 className='text-sm md:text-md text-ellipsis line-clamp-1 mx-4 text-gray-400 bg-gray-200 animate-pulse p-1 rounded-full h-6 w-28 md:w-44 my-1'></h2>
                                {/* Placeholder price */}
                                <div className='flex gap-3 justify-center items-center'>
                                    <p className='text-black text-sm md:text-md font-medium bg-gray-200 animate-pulse p-1 rounded-full w-20 h-4 mb-1'></p>
                                </div>
                            </div>
                        </div>
                    ))
                )  : (
                    // Render actual product data
                    allBestSeller.map((product) => (
                        <Link key={product.product_id} to={`/product/${encodeProductId(product?.product_id)}`}  className='w-full min-w-[144] max-w-[144px] md:min-w-[208] md:max-w-[208px] h-auto flex flex-col justify-center items-center bg-white rounded-sm border' onClick={scrollTop}>
                            <div className='h-36 w-36 md:h-52 md:w-52 p-3 min-w-[144px]'>
                                <img src={SummaryApi.productUnitImage.url + product.unit_product_img1} alt={product.product_name} className='object-scale-down w-full h-full hover:scale-105 transition-all cursor-pointer' />
                            </div>
                            <div>
                                <h2 className='text-sm md:text-md text-ellipsis line-clamp-1 mx-4 text-slate-700 my-1'>{product.product_name}</h2>
                                <div className='flex gap-3 justify-center items-center'>
                                    <p className='text-black text-sm md:text-md font-medium mb-1'>{displayINRCurrency(product.after_discount_price)}</p>
                                </div>
                            </div>
                        </Link>

                    ))
                )}
            </div>
        </div>
    );
};

export default HorizontalCardProduct;