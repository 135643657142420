import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import Cookies from 'js-cookie'; // Ensure you have js-cookie installed
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import { ImCross } from "react-icons/im";
import { IoMdAdd, IoMdArrowDropup } from "react-icons/io";
import displayINRCurrency from '../helpers/displayCurrency';
import scrollTop from '../helpers/scrollTop';
import { IoMdArrowDropdown } from "react-icons/io";
import Context from '../context';

const MIN_DELIVERY_CHARGE = 20; // Minimum delivery charge

const CheckOut = () => {
  const [formData, setFormData] = useState({
    cardNumber: '',
    cardExp: '',
    cardCvc: '',
    upiId: '',
  });
  const navigate = useNavigate();
  const { cartProductCount, fetchUserAddToCart } = useContext(Context);
  useEffect(() => {
    if (cartProductCount === 0) {
      navigate('/cart');
    }
  }, [])
  const [addresses, setAddresses] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({
    id: null, name: '', mobile: '', pincode: '', flatnumber: '', streetname: '', landmark: '', city: ''
  });
  const [modalMode, setModalMode] = useState(''); // New state to manage modal mode
  const [cartItems, setCartItems] = useState([]);

  const [isAddressListVisible, setIsAddressListVisible] = useState(true); // State for visibility
  const [isCartItemsVisible, setIsCartItemsVisible] = useState(false); // State for cart items visibility
  const [isPaymentInfoVisible, setIsPaymentInfoVisible] = useState(true); // State for payment info visibility
  const [loading, setLoading] = useState(false);


  const handleAddressListVisibility = () => {
    setIsAddressListVisible(prev => !prev); // Toggle visibility
  };

  const handleCartItemsVisibility = () => {
    setIsCartItemsVisible(prev => !prev); // Toggle visibility
  };

  const handlePaymentInfoVisibility = () => {
    setIsPaymentInfoVisible(prev => !prev); // Toggle visibility
  };

  useEffect(() => {
    fetchCartItems();
    fetchAllAddress(); // If you also want to fetch addresses here
  }, []);

  const fetchCartItems = async () => {
    try {
      const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA');
      const customerId = encodedCustomerId !== null
        ? decodeURIComponent(atob(encodedCustomerId))
        : null;

      const response = await fetch(SummaryApi.addToCartProductView.url, {
        method: SummaryApi.addToCartProductView.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customerid: customerId }),
      });

      const responseData = await response.json();

      if (responseData.status) {
        setCartItems(responseData.data);
      } else {
        console.error('Failed to fetch cart items:', responseData.message);
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
    }
  };

  useEffect(() => {
    fetchAllAddress();
  }, []);

  const fetchAllAddress = async () => {
    try {
      const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA'); // Example hashcode

      const customerId = encodedCustomerId !== null
        ? decodeURIComponent(atob(encodedCustomerId))
        : null;

      const response = await fetch(SummaryApi.getAddress.url, {
        method: SummaryApi.getAddress.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerid: customerId,
        }),
      });

      const dataResponse = await response.json();

      if (dataResponse.status) {
        const addressData = dataResponse.data;
        const addressList = Array.isArray(addressData) ? addressData : [addressData];
        const formattedAddresses = addressList.map(addr => ({
          id: parseInt(addr.address_id, 10),
          name: addr.customer_name,
          mobile: addr.customer_mobile,
          pincode: addr.pincode,
          flatnumber: addr.flat_number,
          address: addr.address,
          streetname: addr.street_name,
          landmark: addr.land_mark,
          city: addr.city,
          isDefault: addr.is_default === '1', // Convert to boolean
        }));

        // Sort addresses to display default address first
        formattedAddresses.sort((a, b) => (a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1);

        setAddresses(formattedAddresses);

        // Set the default address if available
        const defaultAddress = formattedAddresses.find((addr) => addr.isDefault) || null;
        setCurrentAddress(defaultAddress); // Set currentAddress to default address
      }
    } catch (error) {
      console.error('Failed to fetch addresses:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddressSelect = (e) => {
    const selectedId = parseInt(e.target.value, 10);
    const address = addresses.find((addr) => addr.id === selectedId);
    setCurrentAddress(address); // Update currentAddress
  };

  const handleAddOrUpdateAddress = () => {
    if (modalMode === 'edit' && currentAddress.id) {
      setAddresses((prev) =>
        prev.map((addr) =>
          addr.id === currentAddress.id
            ? { ...addr, ...currentAddress }
            : addr
        )
      );
    } else if (modalMode === 'new') {
      const newAddress = {
        id: addresses.length + 1,
        ...currentAddress,
        isDefault: false,
      };
      setAddresses((prev) => [...prev, newAddress]);
    }
    setCurrentAddress({ id: null, name: '', mobile: '', pincode: '', flatnumber: '', streetname: '', landmark: '', city: '' });
    setModalMode(''); // Reset modal mode
  };

  const handleSetDefaultAddress = async (id) => {
    try {
      // Call the API to update the default address
      const response = await fetch(SummaryApi.updateDefaultAddress.url, {
        method: SummaryApi.updateDefaultAddress.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addressid: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update default address');
      }

      const responseData = await response.json();

      if (responseData.status) {
        // Update local state if API call is successful
        toast.success("Default address updated.")
        setAddresses((prev) =>
          prev.map((addr) =>
            addr.id === id
              ? { ...addr, isDefault: true }
              : { ...addr, isDefault: false }
          )
        );
        const newDefaultAddress = addresses.find((addr) => addr.id === id);
        setCurrentAddress(newDefaultAddress);
        fetchAllAddress();
      } else {
        // Handle error if the API returns a failure status
        console.error('Failed to set default address:', responseData.message);
      }
    } catch (error) {
      console.error('Error setting default address:', error);
    }
  };

  const handleDeleteAddress = async (id) => {
    try {
      // Call the API to delete the address
      const response = await fetch(SummaryApi.deleteAddress.url, {
        method: SummaryApi.deleteAddress.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addressid: id,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to delete address');
      }

      const responseData = await response.json();

      if (responseData.status) {
        toast.success(responseData.message)
        fetchAllAddress()
      } else {
        // Handle error if the API returns a failure status
        console.error('Failed to delete address:', responseData.message);
      }
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  const calculateTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.product_details.after_discount_price * item.quantity, 0);
  };

  const totalPrice = calculateTotalPrice();
  const deliveryCharge = totalPrice > 0 ? MIN_DELIVERY_CHARGE : 0;
  const orderTotal = totalPrice + deliveryCharge;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true); // Set loading to true when processing starts

    const encodedCustomerId = Cookies.get('M8V5R1JQ3K0PWT6Z9LXA');
    const customerId = encodedCustomerId !== null
      ? decodeURIComponent(atob(encodedCustomerId))
      : null;

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        const orderData = {
          customerid: customerId,
          customername: currentAddress?.name,
          mobilenumber: currentAddress?.mobile,
          orderaddress: currentAddress?.address,
          orderamount: totalPrice,
          deliverycharges: deliveryCharge,
          GST: 0,
          othercharges: 0,
          totalamount: orderTotal,
          paymentmode: paymentMethod,
          latitude,
          longitude,
          cartItems: cartItems.map(item => ({
            productunitid: item.product_details.product_unit_id,
            quantity: item.quantity,
          })),
        };

        try {
          const response = await fetch(SummaryApi.orderDetails.url, {
            method: SummaryApi.orderDetails.method,
            headers: {
              'Authorization': 'Bearer oakyaecom_units_916',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderData),
          });

          const responseData = await response.json();

          if (responseData.status) {
            toast.success("Order placed successfully.");
            fetchUserAddToCart();
            navigate('/order-confirmation', { state: { order: responseData.order } });
          } else {
            toast.error(`Order failed: ${responseData.message}`);
          }
        } catch (error) {
          console.error('Error placing order:', error);
          toast.error("An error occurred while placing the order.");
        } finally {
          setLoading(false); // Set loading to false after processing is complete
        }
      },
      (error) => {
        console.error('Error getting geolocation:', error);
        toast.error('Unable to get your location.');
        setLoading(false); // Set loading to false if geolocation fails
      }
    );
  };


  return (
    <section className='min-h-screen bg-gray-100 py-4 md:py-8'>
      <div className='container mx-auto px-4 flex flex-col md:flex-row gap-8'>
        {/* Main Checkout Form */}
        <div className='flex-1'>
          <div className='bg-white p-4 md:p-8 rounded-lg shadow-lg max-w-4xl mx-auto'>
            <h2 className='text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-gray-800 text-center'>Checkout</h2>
            <form onSubmit={handleSubmit} className='space-y-6 md:space-y-8'>
              {/* Address Selection and Management */}
              <div className='bg-gray-50 p-4 md:p-6 rounded-md'>
                <div className='flex items-center justify-between mb-4 cursor-pointer' onClick={handleAddressListVisibility}>
                  <h3 className='text-lg md:text-xl font-semibold text-gray-700'>
                    {addresses.length > 0 ? 'Select Address' : 'Add New Address'}
                  </h3>
                  <span
                    className='text-gray-500'
                  >
                    {isAddressListVisible ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                  </span>
                </div>
                <div className='h-[1px] bg-gray-300 mb-4' />
                <div
                  className={`overflow-hidden transition-all duration-300 ease-out ${isAddressListVisible ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
                >
                  {addresses.length > 0 ? (
                    <div className='space-y-4'>
                      {/* Address List with Edit Button */}
                      <div className='flex flex-col mb-4'>
                        <label className='text-gray-600 mb-2 font-medium'>Previously Used Addresses:</label>
                        {addresses.map((address) => (
                          <div key={address.id} className='flex items-start mb-4'>
                            <input
                              type='radio'
                              name='address'
                              value={address.id}
                              checked={currentAddress ? currentAddress.id === address.id : false}
                              onChange={handleAddressSelect}
                              className='mr-2 mt-1'
                            />
                            <div className='flex-1'>
                              <div className='text-sm md:text-base'>{address.address}</div>
                              <div className='text-xs md:text-sm text-gray-600'>Name: {address.name}</div>
                              <div className='text-xs md:text-sm text-gray-600'>Mobile: {address.mobile}</div>
                              {address.isDefault && <div className='text-xs md:text-sm text-green-600'>Default Address</div>}
                              {!address.isDefault && (
                                <div className='flex mt-2 space-x-4 md:space-x-4 items-center'>
                                  <div className='flex text-xs md:text-sm text-red-600 cursor-pointer hover:underline items-center gap-2' onClick={() => handleDeleteAddress(address.id)}><ImCross />Delete Address</div>
                                  <div className='text-xs md:text-sm text-white bg-green-600 rounded p-1 cursor-pointer hover:underline' onClick={() => handleSetDefaultAddress(address.id)}>Set as Default</div>
                                </div>
                              )}
                            </div>
                            <button
                              type='button'
                              onClick={() => {
                                setCurrentAddress({
                                  id: address.id,
                                  name: address.name,
                                  mobile: address.mobile,
                                  pincode: address.pincode,
                                  flatnumber: address.flatnumber,
                                  streetname: address.streetname,
                                  landmark: address.landmark,
                                  city: address.city,
                                });
                                setModalMode('edit'); // Set mode to edit
                                setIsModalOpen(true);
                              }}
                              className='md:ml-4 bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded-md'
                            >
                              Edit
                            </button>
                          </div>
                        ))}
                        <button
                          type='button'
                          onClick={() => {
                            setCurrentAddress({ id: null, name: currentAddress.name, mobile: currentAddress.mobile, pincode: '', flatnumber: '', streetname: '', landmark: '', city: '' });
                            setModalMode('new'); // Set mode to new
                            setIsModalOpen(true);
                          }}
                          className='flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md'
                        >
                          <IoMdAdd />Add Address
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      type='button'
                      onClick={() => {
                        setCurrentAddress({ id: null, name: '', mobile: '', pincode: '', flatnumber: '', streetname: '', landmark: '', city: '' });
                        setModalMode('new'); // Set mode to new
                        setIsModalOpen(true);
                      }}
                      className='flex bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md items-center gap-1'
                    >
                      <IoMdAdd />Add Address
                    </button>
                  )}
                </div>
              </div>

              {/* Cart Items */}
              <div className='bg-gray-50 p-4 md:p-6 rounded-md'>
                <div className='flex items-center justify-between mb-4 cursor-pointer' onClick={handleCartItemsVisibility}>
                  <h3 className='text-lg md:text-xl font-semibold text-gray-700'>
                    Cart Items
                  </h3>
                  <span
                    className='text-gray-500'
                  >
                    {isCartItemsVisible ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                  </span>
                </div>
                <div className='h-[1px] bg-gray-300 mb-4' />
                {isCartItemsVisible && (
                  <div className='flex flex-col space-y-4'>
                    {cartItems.length > 0 ? (
                      cartItems.map((item) => (
                        <div
                          key={item.cart_id}
                          className='flex flex-col md:flex-row items-start md:items-center p-4 bg-white rounded-lg shadow-md relative'
                        >
                          {/* Product Image and Details */}
                          <div className='flex items-center flex-1'>
                            <img
                              src={SummaryApi.productUnitImage.url + item.product_details.unit_product_img1}
                              alt={item.product_details.product_name}
                              className='h-24 w-24 object-contain mr-4'
                            />
                            <div className='flex flex-col'>
                              <h4 className='text-lg font-semibold'>{item.product_details.product_name}<span className='ml-2'>({item?.product_details?.unit_type})</span></h4>
                              <p className='text-sm text-gray-600'>{item.product_details.category_data.category_name}</p>
                              <p className='text-sm text-gray-600'>{displayINRCurrency(item.product_details.after_discount_price)} x {item.quantity}</p>
                            </div>
                          </div>
                          {/* Price at the Bottom Right */}
                          <p className='text-lg font-semibold absolute bottom-0 right-4 md:static md:bottom-auto md:right-auto'>
                            {displayINRCurrency(item.product_details.after_discount_price * item.quantity)}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p className='text-gray-600'>No items in the cart</p>
                    )}
                  </div>
                )}
              </div>


              {/* Payment Information */}
              <div className='bg-gray-50 p-4 md:p-6 rounded-md'>
                <div className='flex items-center justify-between mb-4 cursor-pointer' onClick={handlePaymentInfoVisibility}>
                  <h3 className='text-lg md:text-xl font-semibold text-gray-700'>
                    Payment Information
                  </h3>
                  <span
                    className='text-gray-500'
                    onClick={handlePaymentInfoVisibility}
                  >
                    {isPaymentInfoVisible ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                  </span>
                </div>
                <div className='h-[1px] bg-gray-300 mb-4' />
                {isPaymentInfoVisible && (
                  <div className='flex flex-col space-y-4'>
                    <label className='text-gray-600 mb-2 font-medium'>Payment Method:</label>
                    <div className='flex flex-wrap'>
                      <div className='flex items-center mr-8 cursor-pointer' onChange={() => setPaymentMethod('Online Payment')}>
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="Online Payment"
                          className="mr-2"
                        />
                        <label className='py-2 rounded-md'>
                          Online Payment
                        </label>
                      </div>

                      <div className='flex items-center cursor-pointer' onChange={() => setPaymentMethod('Pay on delivery')}>
                        <input
                          type="radio"
                          name="paymentMethod"
                          value="Pay on delivery"
                          className="mr-2"
                        />
                        <label className='py-2 rounded-md'>
                          Pay on delivery
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <button
                type='submit'
                className={`${currentAddress?.id !== null && paymentMethod === 'Pay on delivery'
                    ? 'bg-green-600 hover:bg-green-700 text-white'
                    : 'hidden'
                  } px-4 py-2 mx-6 rounded-md ${loading ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                disabled={loading}
              >
                {loading ? 'Placing Order...' : 'Place Order'}
              </button>

            </form>
          </div>
        </div>

        {/* Order Summary */}
        <div className='w-full md:w-80 h-full bg-white p-4 md:p-6 rounded-lg shadow-md'>
          <h3 className='text-lg md:text-xl font-semibold mb-4 text-gray-700'>Order Summary</h3>
          <div className='space-y-4'>
            <div className='flex justify-between text-sm md:text-base'>
              <span className='text-gray-600'>Subtotal:</span>
              <span className='font-semibold'>{displayINRCurrency(totalPrice)}</span>
            </div>
            <div className='flex justify-between text-sm md:text-base'>
              <span className='text-gray-600'>Delivery Charge:</span>
              <span className='font-semibold'>{displayINRCurrency(Math.max(20, deliveryCharge))}</span>
            </div>
            <div className='border-t border-gray-200 my-4'></div>
            <div className='flex justify-between text-lg md:text-xl font-semibold'>
              <span>Total:</span>
              <span>{displayINRCurrency(orderTotal)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Address Management */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleAddOrUpdateAddress}
        address={currentAddress}
        setAddress={setCurrentAddress}
        isEditing={modalMode === 'edit'}
        fetchAddress={fetchAllAddress}
      />
    </section>
  );
};

export default CheckOut;
