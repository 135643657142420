import React, { useEffect, useState } from 'react';
import UploadProduct from '../components/UploadProduct';
import SummaryApi from '../common';
import AdminProductCard from '../components/AdminProductCard';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import AdminEditProduct from '../components/AdminEditProduct';
import { useTable } from 'react-table';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

const AllProducts = () => {
  const [openUploadProduct, setOpenUploadProduct] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [viewMode, setViewMode] = useState('icons'); // 'icons' or 'list'
  const [editProduct, setEditProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const encodeProductId = (id) => {
    if (!id) return '';
    return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
  };

  const fetchAllProduct = async () => {
    const response = await fetch(SummaryApi.adminProduct.url, {
      headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
      },
     });
    const dataResponse = await response.json();
    setAllProduct(dataResponse?.data || []);
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const handleEditProduct = (product) => {
    setEditProduct(product);
  };

  const handleSaveProduct = () => {
    fetchAllProduct(); // Refresh the product list
    setEditProduct(null); // Close the edit form
  };

  const navigateToUnits = (data) => {
    navigate(`/admin-panel/product-units/${encodeProductId(data.product_id)}`, { state: { product: data } });
  };

  const filteredProducts = allProduct.filter(product =>
    product.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    product.product_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredProducts.map(product => ({
      'Product Code': product.product_code,
      'Created Date': new Date(product.created_date).toLocaleDateString('en-IN'),
      'Product Name': product.product_name,
      'Product Sub Title': product.sub_title,
      'Product Description': product.product_description,
      'Price': `₹ ${typeof product.price === 'number' ? product.price.toLocaleString('en-IN') : product.price}`,
      'Discount(%)': product.discount,
      'After Discount': `₹ ${typeof product.after_discount_price === 'number' ? product.after_discount_price.toLocaleString('en-IN') : product.after_discount_price}`,
      'Rating': product.rating,
      'Status': product.is_active === '1' ? 'Active' : 'Inactive',
      'Display': product.is_display === '1' ? 'True' : 'False',
      'Best Seller': product.best_seller === '1' ? 'True' : 'False',
      'Home Display': product.home_display === '1' ? 'True' : 'False',
    })), { header: ["Product Code", "Created Date", "Product Name", "Product Sub Title", "Product Description", "Price", "Discount(%)", "After Discount", "Rating", "Status", "Display", "Best Seller", "Home Display"] });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Products');
    XLSX.writeFile(wb, 'Products.xlsx');
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Product Code',
        accessor: 'product_code',
      },
      {
        Header: 'Created Date',
        accessor: 'created_date',
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {new Date(value).toLocaleDateString('en-IN')}
          </span>
        ),
      },
      {
        Header: 'Product Name',
        accessor: 'product_name',
      },
      {
        Header: 'Product Image',
        accessor: 'default_unit_image',
        Cell: ({ value }) => (
          value ? (
            <img 
              src={SummaryApi.productUnitImage.url + value} 
              alt="Product" 
              className="w-16 h-16 object-contain rounded-md" 
            />
          ) : (
            <span>No Image Available</span>  // Optional: You can display a placeholder text or leave it empty
          )
        ),
      },
      
      {
        Header: 'Product Sub Title',
        accessor: 'sub_title',
      },
      {
        Header: 'Default price',
        accessor: 'after_discount_price',
        Cell: ({ value }) => (
          value != null ? (  // Check if value is not null or undefined
            <span className="text-gray-900 font-medium">
              &#x20B9; {typeof value === 'number' ? value.toLocaleString('en-IN') : value}
            </span>
          ) : (
            <span className="text-gray-500">Price Not Available</span>  // Optional: Placeholder text
          )
        ),
      }
      ,
      {
        Header: 'Rating',
        accessor: 'rating',
      },
      {
        Header: 'Status',
        accessor: 'is_active',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      {
        Header: 'Display',
        accessor: 'is_display',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'True' : 'False'}
          </span>
        ),
      },
      {
        Header: 'Best Seller',
        accessor: 'best_seller',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'True' : 'False'}
          </span>
        ),
      },
      {
        Header: 'Home Display',
        accessor: 'home_display',
        Cell: ({ value }) => (
          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
            {value === '1' ? 'True' : 'False'}
          </span>
        ),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => handleEditProduct(row.original)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Edit
            </button>
            
          </div>
        ),
      },
      {
        Header: 'Action',
        accessor: 'view',
        Cell: ({ row }) => (
          <div className="flex items-center space-x-2">
            <button
              onClick={() => navigateToUnits(row.original)}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            >
              View Units
            </button>
            
          </div>
        ),
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: filteredProducts,
  });

  return (
    <div className="p-4">
      <div className='bg-white py-2 px-4 flex flex-col sm:flex-row justify-between items-center mb-4 shadow-md border rounded-lg'>
        <h2 className='font-bold text-lg md:text-xl'>All Products</h2>

        <div className='flex flex-col md:flex-row items-center'>
          <div className='flex items-center mx-2 text-sm'>
            <div>Active</div>
            <div className='mx-2 text-lg text-green-500'><FaCheckCircle /></div>
            <div className='ml-2'>Inactive</div>
            <div className='mx-2 text-lg text-red-500'><FaTimesCircle /></div>
          </div>
          <button
            className='bg-red-600 text-white hover:bg-red-700 transition-all py-2 px-4 rounded-full mx-1 mb-2 mt-2 md:mt-0 md:mb-0'
            onClick={() => setOpenUploadProduct(true)}
          >
            Upload Product
          </button>
          <button
            className='bg-gray-600 text-white hover:bg-gray-700 transition-all py-2 px-4 rounded-full mx-1'
            onClick={() => {
              setViewMode(viewMode === 'icons' ? 'list' : 'icons');
              setSearchQuery(''); // Clear search query when switching view modes
            }}
          >
            {viewMode === 'icons' ? 'Switch to List' : 'Switch to Icons'}
          </button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mb-4 mt-4">
        <div className="flex-grow md:mr-2">
          <input
            type="text"
            placeholder="Search by name or code..."
            className="p-2 border border-gray-300 rounded w-full mb-2"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        <div>
          <button
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            onClick={() => setSearchQuery('')}
          >
            Clear
          </button>
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
            onClick={downloadExcel}
          >
            Download Excel
          </button>
        </div>
      </div>

      {viewMode === 'icons' ? (
        <div className='flex items-center flex-wrap gap-5 py-4'>
          {filteredProducts.map((product, index) => (
            <AdminProductCard
              data={product}
              key={index + "allProduct"}
              fetchdata={fetchAllProduct}
              onEdit={() => handleEditProduct(product)}
            />
          ))}
        </div>
      ) : (
        <div className='overflow-x-auto'>
          <table {...getTableProps()} className='w-full bg-white shadow-md rounded-lg border'>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className='bg-gray-200'>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className='p-3 text-left whitespace-nowrap'>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='border-b hover:bg-gray-100'>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} className='p-3 text-sm text-gray-800'>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {openUploadProduct && (
        <UploadProduct onClose={() => setOpenUploadProduct(false)} fetchData={fetchAllProduct} />
      )}

      {editProduct && (
        <AdminEditProduct
          productData={editProduct}
          onClose={() => setEditProduct(null)}
          fetchdata={handleSaveProduct}
        />
      )}
    </div>
  );
};

export default AllProducts;
