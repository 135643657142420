import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import UnitEditForm from '../components/UnitEditForm';
import UnitUploadForm from '../components/UnitUploadForm';
import { useTable } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import displayINRCurrency from '../helpers/displayCurrency';

const ProductUnits = () => {
    const [units, setUnits] = useState([]);
    const [names, setNames] = useState([]);
    const [editUnit, setEditUnit] = useState(null);
    const [uploadFormVisible, setUploadFormVisible] = useState(false);

    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate
    const { product } = location.state || {};

    const fetchUnits = async () => {
        const response = await fetch(SummaryApi.adminProductUnit.url, {
            method: SummaryApi.adminProductUnit.method,
            headers: {
                'Authorization': 'Bearer oakyaecom_units_916',
                "content-type": "application/json"
            },
            body: JSON.stringify({ productid: product.product_id })
        });
        const data = await response.json();
        let unitsData = data?.data || [];

        // Sort units: Default units first, then by creation time (assumed to be `created_at` field)
        unitsData = unitsData.sort((a, b) => {
            if (a.is_default_price === '1' && b.is_default_price !== '1') {
                return -1; // Default unit should come before non-default
            }
            if (a.is_default_price !== '1' && b.is_default_price === '1') {
                return 1; // Non-default unit should come after default
            }
            // If both are default or both are non-default, sort by creation time
            return new Date(b.created_at) - new Date(a.created_at); // Newer units first
        });

        setUnits(unitsData);
    };

    const fetchNames = async () => {
        const response = await fetch(SummaryApi.getNames.url, {
            method: SummaryApi.getNames.method,
            headers: {
                'Authorization': 'Bearer oakyaecom_units_916',
                "content-type": "application/json"
            },
            body: JSON.stringify({
                categoryid: product.category_id,
                subcategoryid: product.subcatg_id,
                brandid: product.brand_id
            })
        });
        const data = await response.json();
        setNames(data || []);
    };

    useEffect(() => {
        fetchUnits();
        fetchNames();
    }, []);

    const downloadExcel = () => {
        const ws = XLSX.utils.json_to_sheet(units.map(unit => ({
            'Unit Type': unit.unit_type,
            'Price': `₹ ${typeof unit.price === 'number' ? unit.price.toLocaleString('en-IN') : unit.price}`,
            'Discount(%)': unit.discount,
            'After Discount': `₹ ${typeof unit.after_discount_price === 'number' ? unit.after_discount_price.toLocaleString('en-IN') : unit.after_discount_price}`,
            'About': unit.about,
            'Default Price': unit.is_default_price === '1' ? 'Yes' : 'No',
            'Status': unit.is_active === '1' ? 'Active' : 'Inactive',
        })), { header: ["Unit Type", "Price", "Discount(%)", "After Discount", "About", "Default Price", "Status"] });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Units');
        XLSX.writeFile(wb, 'ProductUnits.xlsx');
    };

    const setDefaultUnit = async (unitId) => {
        try {
            const response = await fetch(SummaryApi.updateDefaultProductUnit.url, {
                method: SummaryApi.updateDefaultProductUnit.method,
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                    "content-type": "application/json",
                },
                body: JSON.stringify({ productunitid: unitId }),
            });
            const data = await response.json();

            if (data.status) {
                toast.success(data.message);
                fetchUnits();
            } else {
                toast.error('Failed to set default unit:', data.message);
            }
        } catch (error) {
            toast.error('Error setting default unit:', error);
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Unit Type',
                accessor: 'unit_type',
            },
            {
                Header: 'Product Image 1',
                accessor: 'unit_product_img1',
                Cell: ({ value }) => (
                    value ? (
                        <img
                            src={SummaryApi.productUnitImage.url + value}
                            alt="Product"
                            className="w-16 h-16 object-contain rounded-lg" // Adjusted size
                        />
                    ) : null
                ),
            },
            {
                Header: 'Product Image 2',
                accessor: 'unit_product_img2',
                Cell: ({ value }) => (
                    value ? (
                        <img
                            src={SummaryApi.productUnitImage.url + value}
                            alt="Product"
                            className="w-16 h-16 object-contain rounded-lg" // Adjusted size
                        />
                    ) : null
                ),
            },
            {
                Header: 'Product Image 3',
                accessor: 'unit_product_img3',
                Cell: ({ value }) => (
                    value ? (
                        <img
                            src={SummaryApi.productUnitImage.url + value}
                            alt="Product"
                            className="w-16 h-16 object-contain rounded-lg" // Adjusted size
                        />
                    ) : null
                ),
            },
            {
                Header: 'Product Image 4',
                accessor: 'unit_product_img4',
                Cell: ({ value }) => (
                    value ? (
                        <img
                            src={SummaryApi.productUnitImage.url + value}
                            alt="Product"
                            className="w-16 h-16 object-contain rounded-lg" // Adjusted size
                        />
                    ) : null
                ),
            },
            {
                Header: 'Price',
                accessor: 'after_discount_price',
                Cell: ({ value }) => (
                    <span>
                        {displayINRCurrency(value)}
                    </span>
                ),
            },
            {
                Header: 'Status',
                accessor: 'is_active',
                Cell: ({ value }) => (
                    <span className={`px-2 py-1 rounded-full text-xs font-semibold ${value === '1' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                        {value === '1' ? 'Active' : 'Inactive'}
                    </span>
                ),
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <button
                        onClick={() => setEditUnit(row.original)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Edit
                    </button>
                ),
            },
            {
                Header: 'Default Price',
                accessor: 'is_default_price',
                Cell: ({ row }) => {
                    const unit = row.original;
                    const isDefault = unit.is_default_price === '1';

                    return (
                        <button
                            onClick={() => {
                                if (!isDefault) {
                                    setDefaultUnit(unit.product_unit_id);
                                }
                            }}
                            disabled={isDefault}
                            className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${isDefault ? 'cursor-not-allowed opacity-50' : ''}`}
                        >
                            {isDefault ? 'Default' : 'Set as default'}
                        </button>
                    );
                },
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: units,
    });

    return (
        <div className="p-4">
            <div className='bg-white py-2 px-4 flex flex-col md:flex-row justify-between items-center mb-4 shadow-md border rounded-lg'>
                <h2 className='font-bold text-lg md:text-xl'>Product Units</h2>

                <div className='flex flex-col md:flex-row items-center'>
                    <button
                        className='bg-red-600 text-white hover:bg-red-700 transition-all py-2 px-4 rounded-full mx-1 mb-2 md:mb-0'
                        onClick={() => setUploadFormVisible(true)}
                    >
                        Upload Product Unit
                    </button>
                    <button
                        className='bg-red-600 text-white hover:bg-red-700 transition-all py-2 px-4 rounded-full mx-1'
                        onClick={downloadExcel}
                    >
                        Download Excel
                    </button>
                    <button
                        className='bg-blue-600 text-white hover:bg-blue-700 transition-all py-2 mt-2 md:mt-0 px-4 rounded-full mx-1'
                        onClick={() => navigate('/admin-panel/all-products')}
                    >
                        All Products
                    </button>
                </div>
            </div>

            <div className='bg-white p-4 shadow-md border rounded-lg mb-4'>
                <h2 className='font-bold text-lg mb-2 border-b'>Product Details</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex flex-col">
                        <span className='font-semibold'>Product Code:</span>
                        <span className='text-gray-700'>{product?.product_code}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className='font-semibold'>Product Name:</span>
                        <span className='text-gray-700'>{product?.product_name}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className='font-semibold'>Category Name:</span>
                        <span className='text-gray-700'>{names?.category_name}</span>
                    </div>
                    {names?.subcategory_name && (
                        <div className="flex flex-col">
                            <span className='font-semibold'>Sub Category Name:</span>
                            <span className='text-gray-700'>{names.subcategory_name}</span>
                        </div>
                    )}

                    {names?.brand_name && (
                        <div className="flex flex-col">
                            <span className='font-semibold'>Brand Name:</span>
                            <span className='text-gray-700'>{names.brand_name}</span>
                        </div>
                    )}

                </div>
            </div>

            <div className='overflow-x-auto'>
                <table {...getTableProps()} className='w-full bg-white shadow-md rounded-lg'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className='bg-gray-200'>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className='p-2 text-left whitespace-nowrap'
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className='border-b hover:bg-gray-100'>
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className='p-2 text-sm text-gray-800'>
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {editUnit && (
                <UnitEditForm
                    unitData={editUnit}
                    onClose={() => setEditUnit(null)}
                    onSave={() => {
                        fetchUnits();
                        setEditUnit(null);
                    }}
                />
            )}

            {uploadFormVisible && (
                <UnitUploadForm
                    onClose={() => setUploadFormVisible(false)}
                    product={product}
                    fetchData={fetchUnits}
                />
            )}
        </div>
    );
};

export default ProductUnits;
