import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import SummaryApi from '../common'
import { IoStar, IoStarOutline } from "react-icons/io5";
import displayINRCurrency from '../helpers/displayCurrency';
import CategroyWiseProductDisplay from '../components/CategoryWiseProductDisplay';
import addToCart from '../helpers/addToCart';
import Context from '../context';
import { RiArrowDropDownLine } from "react-icons/ri";
import { RiArrowDownDoubleLine } from "react-icons/ri";
import { RiArrowUpDoubleLine } from "react-icons/ri";
import { toast } from 'react-toastify';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const ProductDetails = () => {
  const [data, setData] = useState({
    productName: "",
    brandName: "",
    category: "",
    productImage: [],
    description: "",
    price: "",
    sellingPrice: "",
    specifications: ""
  })
  const [unitData, setUnitData] = useState();
  const [allBrand, setAllBrand] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [showAllSpecs, setShowAllSpecs] = useState(false);

  const decodeProductId = (encodedId) => {
    if (!encodedId) return '';
    const decoded = atob(encodedId);
    return decodeURIComponent(decoded);
  };

  // Fetch all categories
  const fetchAllCategory = async () => {
    try {
      const response = await fetch(SummaryApi.allCategory.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
      });
      const dataResponse = await response.json();
      setAllCategory(dataResponse?.data || []);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch all brands
  const fetchAllBrand = async () => {
    try {
      const response = await fetch(SummaryApi.allBrand.url, {
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
      });
      const dataResponse = await response.json();
      setAllBrand(dataResponse?.data || []);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const category = allCategory.find((cat) => cat.category_id === data[0]?.category_id);
  const brand = allBrand.find((br) => br.brand_id === data[0]?.brand_id);
  useEffect(() => {
    fetchAllCategory();
    fetchAllBrand();
  }, []);

  const [showHighlights, setShowHighlights] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const toggleHighlights = () => {
    setShowHighlights(prev => !prev); // Toggle the state of showHighlights
    setIsRotated(prev => !prev);
  };
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const productImageListLoading = new Array(4).fill(null)
  const [activeImage, setActiveImage] = useState("")

  const [zoomImageCoordinate, setZoomImageCoordinate] = useState({
    x: 0,
    y: 0
  })
  const [zoomImage, setZoomImage] = useState(false)

  const { fetchUserAddToCart } = useContext(Context)

  const navigate = useNavigate()

  const fetchProductDetails = async () => {

    try {
      const response = await fetch(SummaryApi.productDetails.url, {
        method: SummaryApi.productDetails.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          "content-type": "application/json"
        },
        body: JSON.stringify({
          productid: decodeProductId(params?.id)
        })
      });

      if (!response.ok) {
        // Handle non-2xx HTTP responses
        throw new Error('Network response was not ok');
      }

      const dataResponse = await response.json();

      // Check if product_img1 to product_img4 have data
      const { unit_product_img1, unit_product_img2, unit_product_img3, unit_product_img4, feature } = dataResponse.data[0];
      const hasProductImages = unit_product_img1 || unit_product_img2 || unit_product_img3 || unit_product_img4;

      if (hasProductImages) {
        // Update product image URLs
        dataResponse.data.productImage = [
          unit_product_img1 ? SummaryApi.productUnitImage.url + unit_product_img1 : null,
          unit_product_img2 ? SummaryApi.productUnitImage.url + unit_product_img2 : null,
          unit_product_img3 ? SummaryApi.productUnitImage.url + unit_product_img3 : null,
          unit_product_img4 ? SummaryApi.productUnitImage.url + unit_product_img4 : null
        ].filter(Boolean); // Filter out null values

        setData(prevData => ({
          ...prevData,
          ...dataResponse.data,
          productImage: dataResponse.data.productImage,
          specifications: feature // Add feature to the state
        }));
        setActiveImage(dataResponse.data.productImage[0]);
      }
    } catch (error) {
      // Handle errors, such as network errors or JSON parsing errors
      console.error('Error fetching product details:', error);
    }
  }


  const fetchProductUnitDetails = async () => {

    try {
      const response = await fetch(SummaryApi.getProductUnitsByID.url, {
        method: SummaryApi.getProductUnitsByID.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          "content-type": "application/json"
        },
        body: JSON.stringify({
          productid: decodeProductId(params?.id)
        })
      });

      if (!response.ok) {
        // Handle non-2xx HTTP responses
        throw new Error('Network response was not ok');
      }

      const dataResponse = await response.json();

      if (dataResponse.status) {
        setUnitData(dataResponse.data);
      } else {
        // Optionally handle the case where status is false
        console.error('Error: ', dataResponse.message || 'Unknown error occurred');
      }
    } catch (error) {
      // Handle errors, such as network errors or JSON parsing errors
      console.error('Error fetching product unit details:', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch product details
        await fetchProductDetails();

        // Fetch product unit details
        await fetchProductUnitDetails();
      } catch (error) {
        console.error('Error occurred during data fetching:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [params]);

  const handleMouseEnterProduct = (imageURL) => {
    setActiveImage(imageURL)
  }

  const handleZoomImage = useCallback((e) => {
    setZoomImage(true)
    const { left, top, width, height } = e.target.getBoundingClientRect()

    const x = (e.clientX - left) / width
    const y = (e.clientY - top) / height

    setZoomImageCoordinate({
      x,
      y
    })
  }, [zoomImageCoordinate])

  const handleLeaveImageZoom = () => {
    setZoomImage(false)
  }


  const handleAddToCart = async (e, id, unit_id, name) => {
    const response = await addToCart(e, id, unit_id)
    if (response.status) {
      toast.success(`${name} added to cart`)
      fetchUserAddToCart()
    } else {
      navigate('/login')
      toast.error(response.message)
    }

  }

  const handleBuyProduct = async (e, id, unit_id) => {
    const response = await addToCart(e, id, unit_id)
    if (response.status) {
      fetchUserAddToCart()
      navigate("/cart")
    } else {
      navigate('/login')
      toast.error(response.message)
    }
  }
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);

    for (let i = 0; i < fullStars; i++) {
      stars.push(<IoStar key={i} />);
    }
    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<IoStarOutline key={stars.length + i} />);
    }

    return stars;
  };

  const handleUnitClick = (unit) => {
    // Extract unit specific data
    const {
      product_unit_id,
      unit_product_img1,
      unit_product_img2,
      unit_product_img3,
      unit_product_img4,
      unit_type,
      about,
      after_discount_price,
      price,
      discount,
      feature,
      is_default_price
    } = unit;

    // Update product images based on the clicked unit's data
    const productImages = [
      unit_product_img1 ? SummaryApi.productUnitImage.url + unit_product_img1 : null,
      unit_product_img2 ? SummaryApi.productUnitImage.url + unit_product_img2 : null,
      unit_product_img3 ? SummaryApi.productUnitImage.url + unit_product_img3 : null,
      unit_product_img4 ? SummaryApi.productUnitImage.url + unit_product_img4 : null
    ].filter(Boolean); // Filter out null values

    // Update state with the selected unit's details
    setData({
      ...data,
      "0": {
        ...data["0"],
        product_unit_id,
        unit_product_img1,
        unit_product_img2,
        unit_product_img3,
        unit_product_img4,
        unit_type,
        about,
        after_discount_price,
        price,
        discount,
        feature
      },
      specifications: feature,
      productImage: productImages // Update productImage array
    });

    // Set active image to the first image in productImages array
    setActiveImage(productImages[0]);
  };

  // Initialize loadingStates with exactly 4 true values
  const [loadingStates, setLoadingStates] = useState(
    Array.from({ length: 4 }, () => true)
  );

  const handleImageLoad = (index) => {
    setLoadingStates((prev) => {
      const updatedStates = [...prev];
      updatedStates[index] = false;
      return updatedStates;
    });
  };

  // Parse the HTML specifications into a list of bullet points
  const parseSpecifications = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const list = doc.querySelector('ul');
    return list ? Array.from(list.querySelectorAll('li')).map(li => li.innerHTML) : [];
  };

  const bulletPoints = parseSpecifications(data.specifications);
  const visiblePoints = bulletPoints.slice(0, 5);
  const hiddenPoints = bulletPoints.slice(5);

  return (
    <div className='container mx-auto p-4'>

      <div className='min-h-[200px] flex flex-col md:flex-row gap-4'>
        {/***product Image */}
        <div className='h-full flex flex-col md:flex-row-reverse gap-4'>

          {/* Placeholder for Product Image */}
          {loading ? (
            <div className='h-full w-full min-h-96 md:h-96 md:w-96 bg-gray-200 rounded-md animate-pulse'></div>
          ) : (
            <div>
              <div className='hidden md:block w-full h-full md:h-96 md:w-96 relative rounded-md'>
                <img
                  src={activeImage}
                  className='h-full w-full min-h-96 object-scale-down rounded-md mix-blend-multiply'
                  onMouseMove={handleZoomImage}
                  onMouseLeave={handleLeaveImageZoom}
                />

                {/* Product Zoom View */}
                {zoomImage && (
                  <div className='hidden md:block absolute overflow-hidden border shadow-lg bg-white p-1 left-[100%] ml-4 top-0 z-10'>
                    <div
                      className='w-[calc(50vw-2rem)] h-[calc(100vh-8rem)] bg-cover bg-no-repeat'
                      style={{
                        background: `url(${activeImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: `${zoomImageCoordinate.x * 100}% ${zoomImageCoordinate.y * 100}%`,
                        backgroundSize: 'contain'
                      }}
                    />
                  </div>
                )}
              </div>

              <div className="flex md:hidden h-full w-full overflow-hidden">
                <Swiper
                  loop
                  spaceBetween={0}
                  pagination={{ clickable: true }}
                  modules={[Autoplay, Pagination]}
                  className="h-full"
                >
                  {data?.productImage?.map((imageUrl, index) => (
                    <SwiperSlide key={index}>
                      <div className="relative w-full h-full flex items-center justify-center">
                        {loadingStates[index] && (
                          <div className={`relative w-full h-96 object-contain flex items-center justify-center ${loadingStates[index] ? 'block' : 'hidden'}`}>
                            <p className="text-black text-sm">Loading...</p>
                          </div>
                        )}
                        <img
                          src={imageUrl}
                          className={`w-full h-96 object-contain ${loadingStates[index] ? 'hidden' : 'block'}`}
                          alt={`Slide ${index}`}
                          onLoad={() => handleImageLoad(index)}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          )}

          <div className='h-full hidden md:block'>
            {
              loading ? (
                <div className='flex gap-2 md:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    productImageListLoading.map((el, index) => {
                      return (
                        <div className='h-20 w-20 bg-slate-200 rounded animate-pulse' key={"loadingImage" + index}>
                        </div>
                      )
                    })
                  }
                </div>

              ) : (
                <div className='flex gap-2 md:flex-col overflow-scroll scrollbar-none h-full'>
                  {
                    data?.productImage?.map((imgURL, index) => {
                      return (
                        <div className='h-20 w-20 rounded-xl' key={imgURL}>
                          <img src={imgURL} className='w-full h-full object-scale-down mix-blend-multiply rounded-xl   cursor-pointer' onMouseEnter={() => handleMouseEnterProduct(imgURL)} onClick={() => handleMouseEnterProduct(imgURL)} />
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          </div>
        </div>

        {/***product details */}
        {
          loading ? (
            <div className='grid gap-1 w-1/2'>
              <p className='bg-slate-200 animate-pulse h-6 md:h-8 w-[250px] rounded-full inline-block'></p>
              <h2 className='text-2xl md:text-4xl font-medium h-6 md:h-8 bg-slate-200 rounded-full animate-pulse w-[250px]'></h2>
              <p className='capitalize text-slate-400 bg-slate-200 min-w-[100px] rounded-full animate-pulse h-6 md:h-8 w-full'></p>
              <div className='flex items-center gap-2 text-2xl md:text-3xl rounded-full font-medium my-1 h-6 md:h-8 animate-pulse w-full'>
                <p className='text-red-600 bg-slate-200 w-full'></p>
                <p className='text-slate-400 line-through bg-slate-200 w-full'></p>
              </div>
              <div className='flex items-center gap-3 my-2 w-full'>
                <button className='h-6 md:h-8 bg-slate-200 rounded-full animate-pulse w-full'></button>
                <button className='h-6 md:h-8 bg-slate-200 rounded-full animate-pulse w-full'></button>
              </div>
              <div className='w-full'>
                <p className='text-slate-600 font-medium my-1 h-6 md:h-8 bg-slate-200 rounded-full animate-pulse w-full'></p>
                <p className='bg-slate-200 rounded-full animate-pulse h-10 md:h-12 w-full'></p>
              </div>
            </div>
          ) :
            (
              <div className='flex flex-col gap-1'>
                <p className='bg-red-200 text-red-600 px-2 rounded-full inline-block w-fit'>{brand?.brand_name}</p>
                <h2 className='text-xl md:text-2xl'>{data[0]?.product_name}</h2>
                <p className='capitalize text-slate-500'>{category?.category_name}</p>

                <div className='text-orange-500 flex items-center gap-1'>
                  {renderStars(data[0]?.rating)}
                  {/* <p className='text-slate-600'>({data[0]?.price - Math.floor(Math.random() * 1000)})</p> */}
                </div>

                <div className='flex gap-2 my-1 items-baseline'>
                  <p className='text-black text-2xl md:text-3xl font-medium'>{displayINRCurrency(data[0]?.after_discount_price)}</p>
                  <p className='text-slate-400 text-sm xl md:text-base font-medium line-through'>{displayINRCurrency(data[0]?.price)}</p>
                  <p className='text-green-700 text-sm xl md:text-base font-medium'>{Math.round(100 - ((data[0]?.after_discount_price) / data[0]?.price) * 100)}% off</p>
                </div>

                <div className='flex items-center gap-3 my-2'>
                  <button className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] text-red-600 font-medium hover:bg-red-600 hover:text-white' onClick={(e) => handleBuyProduct(e, data[0]?.product_id, data[0]?.product_unit_id, data[0]?.product_name)}>Buy</button>
                  <button className='border-2 border-red-600 rounded px-3 py-1 min-w-[120px] font-medium text-white bg-red-600 hover:text-red-600 hover:bg-white' onClick={(e) => handleAddToCart(e, data[0]?.product_id, data[0]?.product_unit_id, data[0]?.product_name)}>Add To Cart</button>
                </div>

                <div>
                  <p className='text-slate-600 font-medium my-1'>Description :</p>
                  <p className='text-sm'>{data[0]?.about}</p>

                  <div className="lg:flex mt-4">
                    {data.specifications && data.specifications.trim() ? (
                      <>
                        <div>
                          <h2 className='text-slate-600 font-medium text-sm flex'>
                            Highlights
                            <span className='hidden lg:inline-block'>:</span>
                            <RiArrowDropDownLine
                              className={`text-xl ml-[3px] mt-[1px] cursor-pointer lg:hidden transform transition-transform duration-400 ${isRotated ? 'rotate-180' : ''}`}
                              onClick={toggleHighlights}
                            />
                          </h2>
                        </div>
                        <div className={showHighlights ? "block" : "hidden lg:block"}>
                          <ul className='pl-6 list-disc text-black font-[350] text-sm'>
                            {visiblePoints.map((point, index) => (
                              <li key={index} dangerouslySetInnerHTML={{ __html: point }} />
                            ))}
                          </ul>
                          {showAllSpecs && (
                            <ul className='pl-6 list-disc text-black font-[350] text-sm'>
                              {hiddenPoints.map((point, index) => (
                                <li key={index} dangerouslySetInnerHTML={{ __html: point }} />
                              ))}
                            </ul>
                          )}
                          {hiddenPoints.length > 0 && (
                            <button
                              className='flex items-center text-blue-600 ml-6 text-sm underline'
                              onClick={() => setShowAllSpecs(prev => !prev)}
                            >
                              {showAllSpecs ? 'View Less' : 'View More'}
                              {showAllSpecs ?  <RiArrowUpDoubleLine/> : <RiArrowDownDoubleLine/>}
                            </button>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>

                </div>
                <div className='mt-4'>
                  <h2 className='text-slate-600 font-medium text-lg'>Unit:<span className='ml-2 text-black'>{data[0]?.unit_type}</span> </h2>
                  <div className='flex flex-wrap gap-4'>
                    {unitData
                      ?.sort((a, b) => (a.is_default_price === '1' ? -1 : b.is_default_price === '1' ? '1' : '0'))
                      .map((unit, index) => (
                        <div
                          key={index}
                          className={`flex items-center border p-2 rounded-lg cursor-pointer shadow-md w-auto ${unit.product_unit_id === data[0]?.product_unit_id
                            ? 'border-red-600 ring-2 ring-red-600'
                            : ''
                            }`}
                          onClick={() => handleUnitClick(unit)}
                        >
                          {unit.unit_product_img1 && (
                            <img
                              src={SummaryApi.productUnitImage.url + unit.unit_product_img1}
                              alt={unit.type}
                              className='h-8 md:16 object-cover mr-4'
                            />
                          )}
                          <div>
                            <h3 className='text-sm md:text-md font-medium'>{unit.unit_type}</h3>
                            <p className='text-xs md:text-sm text-gray-600'>{displayINRCurrency(unit.after_discount_price)}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

              </div>
            )
        }

      </div>


      {
        data[0]?.category_id && (
          <CategroyWiseProductDisplay category={data[0]?.category_id} heading={"Recommended Product"} />
        )
      }




    </div>
  )
}

export default ProductDetails