import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SummaryApi from '../common';
import VerticalCard from '../components/VerticalCard';

const SearchProduct = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const searchTerm = query.get('q');

    const fetchProduct = async () => {
        setLoading(true);
        try {
            const response = await fetch(SummaryApi.searchProduct.url, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ searchterm: searchTerm }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const dataResponse = await response.json();
            setData(dataResponse.data);
        } catch (error) {
            setError('Error fetching data. Please try again later.');
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProduct();
    }, [location.search]);

    return (
        <div className='container mx-auto p-4'>
            {loading && <p className='text-lg text-center'>Loading ...</p>}

            {error && (
                <p className='bg-red-100 text-red-700 text-lg text-center p-4'>
                    {error}
                </p>
            )}

            {!loading && !error && (
                <>
                    <p className='text-lg font-semibold my-3'>
                        Search Results: {data.length}
                    </p>

                    {data.length === 0 && (
                        <p className='bg-white text-lg text-center p-4'>
                            No Data Found....
                        </p>
                    )}

                    {data.length !== 0 && (
                        <VerticalCard loading={loading} data={data} />
                    )}
                </>
            )}
        </div>
    );
};

export default SearchProduct;
