import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { toast } from 'react-toastify';
import SummaryApi from '../common';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import displayINRCurrency from '../helpers/displayCurrency';

const WeeklyOrders = () => {
  const [orderData, setOrderData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);
  const [disabledDays, setDisabledDays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch orders and handle loading and errors
  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(SummaryApi.allOrder.url, { method: SummaryApi.allOrder.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }

      const data = await response.json();
      setOrderData(data.data || []); // Ensure orderData is an array

    } catch (error) {
      setError(error.message);
      toast.error(`Failed to fetch orders: ${error.message}`);
      console.error('Error fetching orders:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    // Set the default selected days and disabled days based on the current day
    const today = new Date().getDay(); // 0 (Sunday) to 6 (Saturday)
    setSelectedDays(Array.from({ length: today + 1 }, (_, index) => index));
    setDisabledDays(Array.from({ length: 7 }, (_, index) => index > today));
  }, []);

  const filterOrders = (orders, searchTerm, selectedDays) => {
    const now = new Date();
    
    // Helper function to strip time from a Date object
    const stripTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    // Define the start and end of the week with time stripped
    const startOfWeek = stripTime(new Date(now.setDate(now.getDate() - now.getDay() + 1))); // Monday of this week
    const endOfWeek = stripTime(new Date(now.setDate(now.getDate() - now.getDay() + 7))); // Sunday of this week


    let filteredOrders = orders.filter(order => {
        const orderDate = stripTime(new Date(order.order_date));
        const dayOfWeek = orderDate.getDay(); // 0 (Sunday) to 6 (Saturday)
        
        // Check if the order date falls within the selected days of the week
        return (
            orderDate >= startOfWeek &&
            orderDate <= endOfWeek &&
            selectedDays.includes(dayOfWeek)
        );
    });

    if (searchTerm) {
        filteredOrders = filteredOrders.filter(order =>
            order.order_code.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    return filteredOrders;
};


  const filteredOrders = filterOrders(orderData, searchTerm, selectedDays);
  const handleClear = () => {
    setSearchTerm('');
    // Reset to all days up to the current day selected
    const today = new Date().getDay();
    setSelectedDays(Array.from({ length: today + 1 }, (_, index) => index));
  };

  const handleDayChange = (day) => {
    setSelectedDays(prev => prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]);
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredOrders.map(order => ({
      'Order Code': order.order_code,
      'Customer Name': order.customer_name,
      'Mobile': order.mobile_number,
      'Order Amount': `₹ ${typeof order.order_amount === 'number' ? order.order_amount.toLocaleString('en-IN') : order.order_amount}`,
      'Delivery Charges': `₹ ${typeof order.delivery_charges === 'number' ? order.delivery_charges.toLocaleString('en-IN') : order.delivery_charges}`,
      'Total Amount': `₹ ${typeof order.total_amount === 'number' ? order.total_amount.toLocaleString('en-IN') : order.total_amount}`,
      'Order Date': new Date(order.order_date).toLocaleDateString('en-IN'),
      'Order Status': order.order_status,
      'Address': order.order_address,
    })));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');
    XLSX.writeFile(wb, 'WeeklyOrders.xlsx');
  };

  const handleEdit = (orderid) => {
    navigate('/admin-panel/edit-order', { state: { orderid } });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Order Code',
        accessor: 'order_code',
      },
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile_number',
      },
      {
        Header: 'Order Amount',
        accessor: 'order_amount',
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {displayINRCurrency(value)}
          </span>
        ),
      },
      {
        Header: 'Delivery Charges',
        accessor: 'delivery_charges',
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {displayINRCurrency(value)}
          </span>
        ),
      },
      {
        Header: 'Total Amount',
        accessor: 'total_amount',
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {displayINRCurrency(value)}
          </span>
        ),
      },
      {
        Header: 'Order Date',
        accessor: 'order_date',
        Cell: ({ value }) => (
          <span className="text-gray-900 font-medium">
            {new Date(value).toLocaleDateString('en-IN')}
          </span>
        ),
      },
      {
        Header: 'Order Status',
        accessor: 'order_status',
        Cell: ({ value }) => {
          let statusClass = 'px-2 py-1 rounded-full text-xs font-semibold whitespace-nowrap'; // Prevent text wrapping
      
          switch (value) {
            case 'Pending':
              statusClass += ' bg-yellow-200 text-yellow-800';
              break;
            case 'Order placed':
              statusClass += ' bg-orange-200 text-orange-800';
              break;
            case 'Processing':
              statusClass += ' bg-purple-200 text-purple-800';
              break;
            case 'Order confirmed':
              statusClass += ' bg-blue-200 text-blue-800';
              break;
            case 'Order in transit':
              statusClass += ' bg-teal-200 text-teal-800';
              break;
            case 'Delivered':
              statusClass += ' bg-green-200 text-green-800';
              break;
            case 'Cancelled':
              statusClass += ' bg-red-200 text-red-800';
              break;
            default:
              statusClass += ' bg-gray-200 text-gray-800'; // Default color for unknown status
              break;
          }
      
          return (
            <span className={statusClass}>
              {value}
            </span>
          );
        },
        // Optional: Set fixed width if needed
        style: { width: '150px' }, // Adjust width as needed
      },
      {
        Header: 'Address',
        accessor: 'order_address',
        Cell: ({ value }) => {
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div className="relative">
              <div
                className={`transition-all duration-300 ease-in-out ${
                  isExpanded ? 'whitespace-normal' : 'truncate'
                }`}
                style={{
                  maxWidth: isExpanded ? 'none' : '200px', // Adjust the max-width as needed
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </div>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="mt-2 text-blue-500 underline hover:text-blue-700"
              >
                {isExpanded ? 'View Less' : 'View More'}
              </button>
            </div>
          );
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <button
            onClick={() => handleEdit(row.original.order_id)} // Assuming `order_id` is the unique identifier
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit
          </button>
        ),
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data: filteredOrders
  });

  return (
    <div className='bg-white p-4 md:p-6 rounded-lg shadow-lg border'>
      <h2 className='text-lg md:text-xl font-semibold mb-4 text-gray-700'>Weekly Orders</h2>

      <div className="flex flex-col md:flex-row mb-4">
        <div className="flex-grow md:mr-2">
          <input
            type="text"
            placeholder="Search by Order Code"
            className="p-2 border border-gray-300 rounded w-full mb-2"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div>
          <button onClick={handleClear} className="md:ml-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
            Clear
          </button>
          <button onClick={downloadExcel} className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Download Excel
          </button>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold text-gray-700 mb-2">Select Days of the Week</h3>
        <div className="flex flex-wrap">
          {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
            <div key={index} className="flex items-center mr-4 mb-2">
              <input
                type="checkbox"
                id={`day-${index}`}
                checked={selectedDays.includes(index)}
                onChange={() => handleDayChange(index)}
                disabled={disabledDays[index]}
                className="mr-2"
              />
              <label htmlFor={`day-${index}`} className={`text-gray-800 ${disabledDays[index] ? 'text-gray-400 cursor-not-allowed' : ''}`}>
                {day}
              </label>
            </div>
          ))}
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center py-4">
          <span>Loading...</span> {/* You can replace this with a spinner component */}
        </div>
      ) : error ? (
        <div className="text-center py-4">
          <span className="text-red-600">Error: {error}</span>
        </div>
      ) : filteredOrders.length === 0 ? (
        <div className="text-center py-4">
          <span>No orders found for the selected criteria.</span>
        </div>
      ) : (
        <div className='overflow-x-auto'>
          <table {...getTableProps()} className='w-full bg-white shadow-md rounded-lg'>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className='bg-gray-200'>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className='p-2 md:p-3 text-left'>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.reverse().map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='border-b hover:bg-gray-100'>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} className='p-2 md:p-3 text-sm text-gray-800'>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default WeeklyOrders;
