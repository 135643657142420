import React from 'react'
import { FaYoutube, FaFacebook, FaTwitter } from 'react-icons/fa'; // Importing social media icons

const Footer = () => {
  return (
    <footer className='bg-gradient-to-r from-slate-200 to-slate-300 py-4'>
      <div className='container mx-auto flex flex-col md:flex-row justify-between items-center px-4'>
        <div className='text-center md:text-left mb-4 md:mb-0'>
          <p className='text-gray-600 text-sm'>
            &copy; 2024 OAKYA SOLUTIONS. All rights reserved.
          </p>
        </div>

        <div className='text-center mb-4 md:mb-0'>
          <p className='font-bold text-lg text-gray-800'>
            Developed By OAKYA SOLUTIONS
          </p>
        </div>

        <div className='flex justify-center space-x-4'>
          <a href='https://youtube.com' target='_blank' rel='noopener noreferrer'>
            <FaYoutube className='text-2xl text-red-600 hover:text-red-800 transition' />
          </a>
          <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
            <FaFacebook className='text-2xl text-blue-600 hover:text-blue-800 transition' />
          </a>
          <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
            <FaTwitter className='text-2xl text-blue-400 hover:text-blue-600 transition' />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
