import React, { useState } from 'react';
import loginIcons from '../assest/signin.gif';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import SummaryApi from '../common';
import { toast } from 'react-toastify';
import OTPVerification from '../components/OTPVerification'; // Import your OTPVerification component here

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [data, setData] = useState({
        fullname: '',
        mobilenumber: '',
        email: '',
        password: '',
        address: '',
        latitude: '',
        longitude: '',
        confirmPassword: '',
        profilePic: '',
        isactive:'',
        createddate: new Date().toISOString(), // Assuming you want current datetime
    });
    const [otp, setOtp] = useState();

    const [showVerification, setShowVerification] = useState(false); // State to control showing the verification popup

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };


    const fetchCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setData((prev) => ({
                            ...prev,
                            latitude: latitude,
                            longitude: longitude,
                        }));
                        console.log(latitude, longitude);
                        resolve({ latitude, longitude });
                    },
                    (error) => {
                        console.error('Error getting geolocation:', error);
                        reject(error);
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
                reject(new Error('Geolocation is not supported'));
            }
        });
    };
    

    const handleSignUpSuccess = () => {
        setShowVerification(true); // Show the verification modal
    };

    const handleVerificationClose = () => {
        setShowVerification(false); // Close the verification modal
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (data.password === data.confirmPassword) {
            try {
                const { latitude, longitude } = await fetchCurrentLocation();
    
                const updatedData = {
                    ...data,
                    latitude: latitude,
                    longitude: longitude,
                };
    
                const dataResponse = await fetch(SummaryApi.signUP.url, {
                    method: SummaryApi.signUP.method,
                    headers: {
                        'Authorization': 'Bearer oakyaecom_units_916',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedData),
                });
    
                const responseData = await dataResponse.json();
                console.log(responseData);
                console.log(updatedData); // Log the updated data
                setOtp(responseData.otp)
                if (responseData.status) {
                    toast.success(responseData.message);
                    handleSignUpSuccess(); // Show otp verification popup
                } else {
                    toast.error(responseData.message || 'Error occurred during registration');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Error occurred during registration');
            }
        } else {
            toast.error('Please check password and confirm password');
        }
    };
    

    return (
        <section id='signup'>
            <div className='mx-auto container p-4'>
                <div className='bg-white p-5 w-full max-w-sm mx-auto'>
                    <div className='w-20 h-20 mx-auto relative overflow-hidden rounded-full'>
                        <div>
                            <img src={data.profilePic || loginIcons} alt='login icons' />
                        </div>
                    </div>

                    <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
                        <div className='grid'>
                            <label>Full Name:</label>
                            <div className='bg-slate-100 p-2 border'>
                                <input
                                    type='text'
                                    placeholder='Enter your full name'
                                    name='fullname'
                                    value={data.fullname}
                                    onChange={handleOnChange}
                                    required
                                    className='w-full h-full outline-none bg-transparent'
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <label>Email:</label>
                            <div className='bg-slate-100 p-2 border'>
                                <input
                                    type='email'
                                    placeholder='Enter email'
                                    name='email'
                                    value={data.email}
                                    onChange={handleOnChange}
                                    required
                                    className='w-full h-full outline-none bg-transparent'
                                />
                            </div>
                        </div>
                        <div className='grid'>
                            <label>Mobile Number:</label>
                            <div className='bg-slate-100 p-2 border'>
                                <input
                                    type='text'
                                    placeholder='Enter your mobile number'
                                    name='mobilenumber'
                                    value={data.mobilenumber}
                                    onChange={handleOnChange}
                                    required
                                    className='w-full h-full outline-none bg-transparent'
                                />
                            </div>
                        </div>
                        <div className='hidden'>
                            <label>Password:</label>
                            <div className='bg-slate-100 p-2 flex'>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Enter password'
                                    value={data.password}
                                    name='password'
                                    onChange={handleOnChange}
                                    
                                    className='w-full h-full outline-none bg-transparent'
                                />
                                <div
                                    className='cursor-pointer text-xl'
                                    onClick={() => setShowPassword((prev) => !prev)}
                                >
                                    <span>{showPassword ? <FaEyeSlash /> : <FaEye />}</span>
                                </div>
                            </div>
                        </div>
                        <div className='hidden'>
                            <label>Confirm Password:</label>
                            <div className='bg-slate-100 p-2 flex'>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder='Enter confirm password'
                                    value={data.confirmPassword}
                                    name='confirmPassword'
                                    onChange={handleOnChange}
                                    
                                    className='w-full h-full outline-none bg-transparent'
                                />
                                <div
                                    className='cursor-pointer text-xl'
                                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                                >
                                    <span>{showConfirmPassword ? <FaEyeSlash /> : <FaEye />}</span>
                                </div>
                            </div>
                        </div>
                        <button className='bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                            Sign Up
                        </button>
                    </form>
                    <p className='my-5'>
                        Already have an account?{' '}
                        <Link to={'/login'} className='text-red-600 hover:text-red-700 hover:underline'>
                            Login
                        </Link>
                    </p>
                </div>
            </div>

            {/* Render the OTPVerification component as a modal */}
            {showVerification && (
                <OTPVerification
                    onClose={handleVerificationClose}
                    email={data.email} // Pass email number to OTPVerification
                    otp = {otp}
                />
            )}
        </section>
    );
};

export default SignUp;
