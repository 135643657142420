import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import SummaryApi from '../common';
import UploadBrand from '../components/UploadBrand';
import AdminBrandCard from '../components/AdminBrandCard';

const AllBrands = () => {
  const [openUploadBrand, setOpenUploadBrand] = useState(false);
  const [allBrands, setAllBrands] = useState([]);

  const fetchAllBrands = async () => {
    const response = await fetch(SummaryApi.adminBrand.url, {
      headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
          'Content-Type': 'application/json',
      },
  });
    const dataResponse = await response.json();
    setAllBrands(dataResponse?.data || []);
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  return (
    <div className="p-4">
      <div className='bg-white py-2 px-4 flex flex-col sm:flex-row justify-between items-center mb-4 shadow-md border rounded-lg'>
        <h2 className='font-bold text-lg md:text-xl'>All Brands</h2>

        <div className='flex flex-col md:flex-row items-center'>
          <div className='flex items-center mx-2 text-sm'>
            <div>Active</div>
            <div className='mx-2 text-lg text-green-500'><FaCheckCircle /></div>
            <div className='ml-2'>Inactive</div>
            <div className='mx-2 text-lg text-red-500'><FaTimesCircle /></div>
          </div>
          <button
            className='bg-red-600 text-white hover:bg-red-700 transition-all py-2 px-4 rounded-full mx-1 mb-2 mt-2 md:mt-0 md:mb-0'
            onClick={() => setOpenUploadBrand(true)}
          >
            Upload Brand
          </button>
        </div>
      </div>

      {/* Display brands using AdminBrandCard */}
      <div className='flex items-center flex-wrap gap-5 py-4'>
        {allBrands.map((brand, index) => (
          <AdminBrandCard
            key={index}
            data={brand}
            fetchdata={fetchAllBrands}
          />
        ))}
      </div>

      {/* Upload brand component */}
      {openUploadBrand && (
        <UploadBrand
          onClose={() => setOpenUploadBrand(false)}
          fetchdata={fetchAllBrands}
        />
      )}
    </div>
  );
};

export default AllBrands;
