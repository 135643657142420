import React, { useState } from 'react';
import { CgClose } from "react-icons/cg";
import { FaCloudUploadAlt } from "react-icons/fa";
import DisplayImage from './DisplayImage'; 
import { MdDelete } from "react-icons/md";
import { toast } from 'react-toastify';
import Switch from "react-switch"; 
import SummaryApi from '../common';

const UploadCategory = ({
  onClose,
  fetchdata
}) => {
  const [data, setData] = useState({
    categoryname: "",
    image: null, 
    imageUrl: "", 
    isactive: true, 
  });
  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to handle changes in input fields
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Function to handle file upload
  const handleUploadProduct = async (e) => {
    const file = e.target.files[0]; 
    if (file) {
      setData((prev) => ({
        ...prev,
        image: file, 
        imageUrl: URL.createObjectURL(file) 
      }));

      
      console.log(file); 
    }
  };

  // Function to delete the uploaded image
  const handleDeleteProductImage = () => {
    setData((prev) => ({
      ...prev,
      image: null, 
      imageUrl: "" 
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    if (data.image === "") {
      toast.error('Image upload required');
      setLoading(false); 
      return;
    }
    try {
      const formData = new FormData();
      formData.append('categoryname', data.categoryname);
      if (data.image) {
        formData.append('image', data.image);
      }
      formData.append('isactive', data.isactive ? 1 : 0);

      const response = await fetch(SummaryApi.uploadCategory.url, {
        method: SummaryApi.uploadCategory.method,
        headers: {
          'Authorization': 'Bearer oakyaecom_units_916',
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload category');
      }

      const responseData = await response.json();

      if (responseData.status) {
        toast.success(responseData.message);
        onClose();
        fetchdata();
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error('Error uploading category');
      console.error('Error:', error);
    } finally {
      setLoading(false); 
    }
  };

  // Function to handle toggle of the active switch
  const handleActiveToggle = (checked) => {
    setData((prev) => ({
      ...prev,
      isactive: checked
    }));
  };

  return (
    <div className='fixed w-full h-full bg-slate-200 bg-opacity-35 top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
      <div className='relative bg-white p-4 rounded w-full max-w-2xl max-h-[80%] overflow-y-auto'>
        <div className='flex justify-between items-center pb-3'>
          <h2 className='font-bold text-lg'>Upload Category</h2>
          <div className='w-fit ml-auto text-2xl hover:text-red-600 cursor-pointer' onClick={onClose}>
            <CgClose />
          </div>
        </div>

        <form className='grid p-4 gap-2 h-full pb-5' onSubmit={handleSubmit}>
          <label htmlFor='category' className='mt-3'>Category Name:</label>
          <input
            required
            value={data.categoryname}
            name='categoryname'
            onChange={handleOnChange}
            placeholder='Enter category name'
            className='p-2 h-10 bg-slate-100 border rounded'
          />

          <label htmlFor='image' className='mt-3'>Category Image:</label>
          <label htmlFor='uploadImageInput'>
            <div className={`p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center cursor-pointer ${loading ? 'cursor-not-allowed' : ''}`}>
              <div className='text-slate-500 flex justify-center items-center flex-col gap-2'>
                <span className='text-4xl'><FaCloudUploadAlt /></span>
                <p className='text-sm'>Upload Category Image</p>
                <input type='file' id='uploadImageInput' className='hidden' onChange={handleUploadProduct}  />
              </div>
            </div>
          </label>

          {data.imageUrl && (
            <div className='flex items-center gap-2'>
              <div className='relative group'>
                <img
                  src={data.imageUrl}
                  alt='Uploaded Image'
                  className='bg-slate-100 border cursor-pointer w-28'
                  onClick={() => setOpenFullScreenImage(true)}
                />
                <div
                  className='absolute bottom-0 right-0 p-1 text-white bg-red-600 rounded-full hidden group-hover:block cursor-pointer'
                  onClick={handleDeleteProductImage}
                >
                  <MdDelete />
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center py-3">
            <Switch
              onChange={handleActiveToggle}
              checked={data.isactive}
              height={24} 
              width={78} 
              handleDiameter={20}
              handleStyle={{
                backgroundColor: '#4caf50', 
                boxShadow: '0 0 2px 3px rgba(0,0,0,0.1)', 
              }}
              onColor="#008000" 
              offColor="#D22B2B" 
              checkedIcon={<div className="flex justify-center items-center h-full text-sm ml-3 text-white"> Active </div>}
              uncheckedIcon={<div className="flex justify-center items-center h-full text-sm mr-5 text-white"> InActive </div>}
              className="mt-2 "
            />
          </div>

          <button type="submit" className='px-3 py-2 bg-red-600 text-white mb-10 hover:bg-red-700' >
            Upload Category
          </button>
        </form>

        {loading && (
          <div className='absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-75 flex justify-center items-center'>
            <div className='text-lg font-bold'>Uploading Category...</div>
          </div>
        )}
      </div>

      {/* Display full-screen image */}
      {openFullScreenImage && (
        <DisplayImage onClose={() => setOpenFullScreenImage(false)} imgUrl={data.imageUrl} />
      )}
    </div>
  );
};

export default UploadCategory;
