import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useTable } from 'react-table';
import SummaryApi from '../common'; // Import the SummaryApi object
import { BsCurrencyRupee } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { FaCogs, FaBox, FaTags, FaBoxOpen, FaUser, FaMoneyBillWave, FaCheckCircle, FaTimesCircle, FaCalendarDay } from 'react-icons/fa';
import { GiBoxUnpacking } from 'react-icons/gi';
import displayINRCurrency from '../helpers/displayCurrency';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [categoryCounts, setCategoryCounts] = useState([]);
  const [productOrderCounts, setProductOrderCounts] = useState([]);
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [overallCounts, setOverallCounts] = useState({
    categories: 0,
    subCategories: 0,
    brands: 0,
    products: 0,
    customers: 0,
    orders: 0,
  });
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [todayOrdersCount, setTodayOrdersCount] = useState(0);
  const [TodayDeliveredOrdersCount, setTodayDeliveredOrdersCount] = useState(0);
  const [TodayCancelledOrdersCount, setTodayCancelledOrdersCount] = useState(0);
  const [TotalDeliveredOrdersCount, setTotalDeliveredOrdersCount] = useState(0);
  const [TotalCancelledOrdersCount, setTotalCancelledOrdersCount] = useState(0);
  const [todayRevenue, setTodayRevenue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Helper function to fetch and handle API responses
        const fetchAndHandle = async (url, method) => {
          try {
            const response = await fetch(url, { method, headers: {
              'Authorization': 'Bearer oakyaecom_units_916',
              'Content-Type': 'application/json',
            }, });
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            return data.data || []; // Return empty array if no data
          } catch (error) {
            console.error(`Error fetching data from ${url}:`, error);
            return []; // Return empty array in case of error
          }
        };
  
        // Fetch data from APIs with error handling
        const [
          categoriesData,
          brandsData,
          subCategoriesData,
          productsData,
          customersData,
          ordersData
        ] = await Promise.all([
          fetchAndHandle(SummaryApi.allCategory.url, SummaryApi.allCategory.method),
          fetchAndHandle(SummaryApi.allBrand.url, SummaryApi.allBrand.method),
          fetchAndHandle(SummaryApi.allSubCategory.url, SummaryApi.allSubCategory.method),
          fetchAndHandle(SummaryApi.allProduct.url, SummaryApi.allProduct.method),
          fetchAndHandle(SummaryApi.allUser.url, SummaryApi.allUser.method),
          fetchAndHandle(SummaryApi.allOrder.url, SummaryApi.allOrder.method),
        ]);
  
        // Set overall counts
        setOverallCounts({
          categories: categoriesData.length,
          brands: brandsData.length,
          subCategories: subCategoriesData.length,
          products: productsData.length,
          customers: customersData.length,
          orders: ordersData.length,
        });
  
        // Calculate product count for each category and sub-category
        const categoryProductCounts = categoriesData.map(category => ({
          category_name: category.category_name,
          product_count: productsData.filter(product => product.category_id === category.category_id).length,
        }));
        setCategoryCounts(categoryProductCounts);
  
        // Calculate the number of orders for each product
        const productOrderCounts = {};
        ordersData.forEach(order => {
          order.order_items.forEach(item => {
            if (!productOrderCounts[item.product_name]) {
              productOrderCounts[item.product_name] = 0;
            }
            productOrderCounts[item.product_name] += parseInt(item.quantity, 10);
          });
        });
  
        const productOrderCountsArray = Object.keys(productOrderCounts).map(product_name => ({
          product_name,
          order_count: productOrderCounts[product_name],
        }));
        setProductOrderCounts(productOrderCountsArray);
  
        setTotalDeliveredOrdersCount(ordersData.filter(order => order.order_status === 'Delivered').length);
        setTotalCancelledOrdersCount(ordersData.filter(order => order.order_status === 'Cancelled').length);
  
        // Filter orders for today
        const today = new Date().toISOString().split('T')[0];
        const todayOrders = ordersData.filter(order => order.order_date.startsWith(today));
  
        // Set today's orders count, delivered and cancelled orders count
        setTodayOrdersCount(todayOrders.length);
        setTodayDeliveredOrdersCount(ordersData.filter(order => order.delivery_date === today).length);
        setTodayCancelledOrdersCount(ordersData.filter(order => order.cancelled_date === today).length);
  
        // Calculate today's revenue
        const todayRevenue = todayOrders.reduce((total, order) => total + parseFloat(order.order_amount), 0);
        setTodayRevenue(todayRevenue);
  
        // Set order data
        setOrderData(todayOrders);
  
        // Calculate best-selling products
        const sortedProducts = productOrderCountsArray.sort((a, b) => b.order_count - a.order_count);
        setBestSellingProducts(sortedProducts.slice(0, 5)); // Top 5 products
  
        // Calculate total revenue
        const totalRevenue = ordersData.reduce((total, order) => total + parseFloat(order.order_amount), 0);
        setTotalRevenue(totalRevenue);
  
      } catch (error) {
        toast.error(`Failed to fetch data: ${error.message}`);
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  


  // Prepare data for bar chart
  const barData = {
    labels: productOrderCounts.map(product => product.product_name),
    datasets: [
      {
        label: 'Number of Orders',
        data: productOrderCounts.map(product => product.order_count),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw} orders`;
          },
        },
      },
    },
  };

  // Prepare data for pie chart
  const orderStatusCounts = orderData.reduce((acc, order) => {
    const status = order.order_status;
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  const pieData = {
    labels: Object.keys(orderStatusCounts),
    datasets: [
      {
        data: Object.values(orderStatusCounts),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.raw} orders`;
          },
        },
      },
    },
  };

  // Prepare data for order details table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Order Code',
        accessor: 'order_code',
      },
      {
        Header: 'Customer Name',
        accessor: 'customer_name',
      },
      {
        Header: 'Mobile',
        accessor: 'mobile_number',
      },
      {
        Header: 'Order Amount',
        accessor: 'order_amount',
        Cell: ({ value }) => {
          return (
            <span className="text-gray-900 font-medium">
              {displayINRCurrency(value)}
            </span>
          );
        },
      },
      {
        Header: 'Delivery Charges',
        accessor: 'delivery_charges',
        Cell: ({ value }) => {
          return (
            <span className="text-gray-900 font-medium">
              {displayINRCurrency(value)}
            </span>
          );
        },
      },
      {
        Header: 'Total Amount',
        accessor: 'total_amount',
        Cell: ({ value }) => {
          return (
            <span className="text-gray-900 font-medium">
              {displayINRCurrency(value)}
            </span>
          );
        },
      },
      {
        Header: 'Order Date',
        accessor: 'order_date',
        Cell: ({ value }) => {
          return (
            <span className="text-gray-900 font-medium">
              {new Date(value).toLocaleDateString('en-IN')}
            </span>
          );
        },
      },
      {
        Header: 'Order Status',
        accessor: 'order_status',
        Cell: ({ value }) => {
          let statusClass = 'px-2 py-1 rounded-full text-xs font-semibold whitespace-nowrap'; // Prevent text wrapping
      
          switch (value) {
            case 'Pending':
              statusClass += ' bg-yellow-200 text-yellow-800';
              break;
            case 'Order placed':
              statusClass += ' bg-orange-200 text-orange-800';
              break;
            case 'Processing':
              statusClass += ' bg-purple-200 text-purple-800';
              break;
            case 'Order confirmed':
              statusClass += ' bg-blue-200 text-blue-800';
              break;
            case 'Order in transit':
              statusClass += ' bg-teal-200 text-teal-800';
              break;
            case 'Delivered':
              statusClass += ' bg-green-200 text-green-800';
              break;
            case 'Cancelled':
              statusClass += ' bg-red-200 text-red-800';
              break;
            default:
              statusClass += ' bg-gray-200 text-gray-800'; // Default color for unknown status
              break;
          }
      
          return (
            <span className={statusClass}>
              {value}
            </span>
          );
        },
        // Optional: Set fixed width if needed
        style: { width: '150px' }, // Adjust width as needed
      }
      
      ,
      {
        Header: 'Address',
        accessor: 'order_address',
        Cell: ({ value }) => {
          const [isExpanded, setIsExpanded] = useState(false);
          return (
            <div className="relative">
              <div
                className={`transition-all duration-300 ease-in-out ${
                  isExpanded ? 'whitespace-normal' : 'truncate'
                }`}
                style={{
                  maxWidth: isExpanded ? 'none' : '200px', // Adjust the max-width as needed
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </div>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="mt-2 text-blue-500 underline hover:text-blue-700"
              >
                {isExpanded ? 'View Less' : 'View More'}
              </button>
            </div>
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <button
            onClick={() => handleEdit(row.original.order_id)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit
          </button>
        ),
      },
    ],
    []
  );
  const handleEdit = (orderid) => {
    navigate('/admin-panel/edit-order', { state: { orderid } });
  };

  const tableData = React.useMemo(() => orderData, [orderData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: tableData });

  return (
    <div className='md:p-6 min-h-screen'>
      <h1 className='text-xl md:text-2xl font-bold mb-6 text-gray-800'>Dashboard</h1>

      {/* Summary Section */}
      <div className='bg-white p-4 md:p-6 border rounded-lg shadow-lg mb-6'>
        <h2 className='text-lg md:text-xl font-semibold mb-4 text-gray-700'>Summary</h2>
        <div className='grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6'>
          <div className='bg-blue-50 p-2 md:p-4 rounded-lg text-center'>
            <FaCogs className='text-blue-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-blue-600'>Categories</h3>
            <p className='text-lg md:text-xl font-bold text-blue-700'>{overallCounts.categories}</p>
          </div>
          <div className='bg-green-50 p-2 md:p-4 rounded-lg text-center'>
            <FaTags className='text-green-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-green-600'>Brands</h3>
            <p className='text-lg md:text-xl font-bold text-green-700'>{overallCounts.brands}</p>
          </div>
          <div className='bg-yellow-50 p-2 md:p-4 rounded-lg text-center'>
            <GiBoxUnpacking className='text-yellow-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-yellow-600'>Sub-Categories</h3>
            <p className='text-lg md:text-xl font-bold text-yellow-700'>{overallCounts.subCategories}</p>
          </div>
          <div className='bg-purple-50 p-2 md:p-4 rounded-lg text-center'>
            <FaBoxOpen className='text-purple-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-purple-600'>Products</h3>
            <p className='text-lg md:text-xl font-bold text-purple-700'>{overallCounts.products}</p>
          </div>
          <div className='bg-red-50 p-2 md:p-4 rounded-lg text-center'>
            <FaUser className='text-red-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-red-600'>Customers</h3>
            <p className='text-lg md:text-xl font-bold text-red-700'>{overallCounts.customers}</p>
          </div>
          <div className='bg-gray-50 p-2 md:p-4 rounded-lg text-center'>
            <FaMoneyBillWave className='text-gray-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-gray-600'>Revenue</h3>
            <p className='flex text-lg md:text-xl font-bold text-gray-700 justify-center items-center'>
              {displayINRCurrency(totalRevenue)}
            </p>
          </div>
          <div className='bg-teal-50 p-2 md:p-4 rounded-lg text-center'>
            <FaBox className='text-teal-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-teal-600'>Total Orders</h3>
            <p className='text-lg md:text-xl font-bold text-teal-700'>{overallCounts.orders}</p>
          </div>
          <div className='bg-green-50 p-2 md:p-4 rounded-lg text-center'>
            <FaCheckCircle className='text-green-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-green-600'>Total Delivered Orders</h3>
            <p className='text-lg md:text-xl font-bold text-green-700'>{TotalDeliveredOrdersCount}</p>
          </div>
          <div className='bg-red-50 p-2 md:p-4 rounded-lg text-center'>
            <FaTimesCircle className='text-red-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-red-600'>Total Cancelled Orders</h3>
            <p className='text-lg md:text-xl font-bold text-red-700'>{TotalCancelledOrdersCount}</p>
          </div>
          <div className='bg-orange-50 p-2 md:p-4 rounded-lg text-center'>
            <FaCalendarDay className='text-orange-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-orange-600'>Today's Orders</h3>
            <p className='text-lg md:text-xl font-bold text-orange-700'>{todayOrdersCount}</p>
          </div>
          <div className='bg-green-50 p-2 md:p-4 rounded-lg text-center'>
            <FaCheckCircle className='text-green-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-green-600'>Today Delivered Orders</h3>
            <p className='text-lg md:text-xl font-bold text-green-700'>{TodayDeliveredOrdersCount}</p>
          </div>
          <div className='bg-red-50 p-2 md:p-4 rounded-lg text-center'>
            <FaTimesCircle className='text-red-500 text-3xl mb-2 mx-auto' />
            <h3 className='text-sm md:text-lg font-medium mb-2 text-red-600'>Today's Cancelled Orders</h3>
            <p className='text-lg md:text-xl font-bold text-red-700'>{TodayCancelledOrdersCount}</p>
          </div>
        </div>
      </div>


      {/* Order Details Section */}
      <div className='bg-white border p-4 md:p-6 rounded-lg shadow-lg mb-6'>
        <div className='flex flex-col md:flex-row justify-between items-center'>
          <h2 className='text-sm md:text-xl font-semibold mb-2 md:mb-0 text-gray-700'>Today's Orders</h2>
          <div className='flex items-center'>
            <h3 className='text-sm md:text-lg font-medium text-red-700 mr-1 md:mr-2'>Today's Revenue:</h3>
            <p className='flex items-center text-sm md:text-xl font-bold text-gray-800'>
              {displayINRCurrency(todayRevenue)}
            </p>
          </div>
        </div>


        <div className='overflow-x-auto'>
          <table {...getTableProps()} className='w-full bg-white shadow-md rounded-lg'>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()} className='bg-gray-200'>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()} className='p-2 md:p-3 text-left'>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.reverse().map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className='border-b'>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} className='p-2 md:p-3'>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Charts Section */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div className='bg-white p-4 md:p-6 border rounded-lg shadow-lg'>
          <h2 className='text-lg md:text-xl font-semibold mb-4 text-gray-700'>Product Orders</h2>
          <div className='relative' style={{ height: '300px' }}>
            <Bar data={barData} options={barOptions} />
          </div>
        </div>
        <div className='bg-white p-4 md:p-6 border rounded-lg shadow-lg'>
          <h2 className='text-lg md:text-xl font-semibold mb-4 text-gray-700'>Order Status</h2>
          <div className='relative' style={{ height: '300px' }}>
            <Pie data={pieData} options={pieOptions} />
          </div>
        </div>
      </div>

      {/* Top Selling Products Section */}
      <div className='bg-white p-4 md:p-6 rounded-lg border mt-4 shadow-lg mb-6'>
        <h2 className='text-lg md:text-xl font-semibold mb-4 text-gray-700'>Top Selling Products</h2>
        <div>
          {bestSellingProducts.map((product, index) => (
            <div key={index} className='flex items-center border-b border-gray-200 py-2'>
              <div className='flex-1'>
                <h3 className='text-lg font-medium'>{product.product_name}</h3>
                <p className='text-gray-800'>{product.order_count} orders</p>
              </div>
              <div className='text-gray-500'>
                {/* Add any additional information or actions here */}
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Dashboard;
