import React, { useState } from 'react'
import { MdModeEditOutline } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import displayINRCurrency from '../helpers/displayCurrency';
import { useNavigate } from 'react-router-dom';
import SummaryApi from '../common';

const AdminProductCard = ({
  data,
  fetchdata
}) => {
  const [editProduct, setEditProduct] = useState(false)
  const navigate = useNavigate();

  const encodeProductId = (id) => {
    if (!id) return '';
    return btoa(encodeURIComponent(id)); // Encode and then Base64 encode
  };

  const navigateToUnits = () => {
    navigate(`/admin-panel/product-units/${encodeProductId(data.product_id)}`, { state: { product: data } });
  };

  // Determine the image source
  const imageSrc = data.default_unit_image
    ? SummaryApi.productUnitImage.url + data.default_unit_image
    : SummaryApi.productImage.url + data.product_img1;

  return (
    <div className='bg-white p-4 rounded relative'>
      {/* Conditional dot */}
      {data.is_active == 1 && (
        <div className='absolute top-2 right-6 text-md text-[#008000]'><FaCheckCircle /></div>
      )}
      {data.is_active == 0 && (
        <div className='absolute top-2 right-6 text-md text-[#D22B2B]'><FaTimesCircle /></div>
      )}
      <div className='w-40'>
        <div className='w-32 h-32 flex justify-center items-center'>
          <img
            src={imageSrc}
            className='mx-auto object-fill h-full'
            alt={data.product_name}
          />
        </div>
        <h1 className='text-ellipsis line-clamp-2'>{data.product_name}</h1>

        <div>
          <div className='flex items-center'>
            <p className='font-semibold'>
              {displayINRCurrency(data.after_discount_price)}
            </p>
            <div className='w-fit ml-auto p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer' onClick={() => setEditProduct(true)}>
              <MdModeEditOutline />
            </div>
          </div>
          <button className='bg-blue-500 w-full font-semibold hover:bg-blue-600 rounded-md p-2 text-white cursor-pointer mt-2' onClick={() => navigateToUnits()}>View Units</button>
        </div>
      </div>

      {editProduct && (
        <AdminEditProduct productData={data} onClose={() => setEditProduct(false)} fetchdata={fetchdata} />
      )}

    </div>
  )
}

export default AdminProductCard
