import React, { useContext, useState } from 'react';
import Cookies from 'js-cookie'; // Import js-cookie library
import loginIcons from '../assest/signin.gif';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Context from '../context';
import SummaryApi from '../common';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [emailExists, setEmailExists] = useState(false); // Track if email exists in retrieved data
    const [data, setData] = useState({
        email: '',
        otp: '',
    });
    const navigate = useNavigate();
    const { fetchUserAddToCart } = useContext(Context);
    const context = useContext(Context)

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(SummaryApi.verifyEmail.url, {
                method: SummaryApi.verifyEmail.method, // Assuming POST method is required by userlogin.php
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data.email,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to verify email');
            }

            const responseData = await response.json();

            if (responseData.status) {
                toast.success('Email exists. Please enter OTP sent to your email.');
                setEmailExists(true);
                setEmailSubmitted(true); // Proceed to OTP input
            } else {
                toast.error('Email not found. Please check your email.');
            }
        } catch (error) {
            console.error('Error checking email existence:', error);
            toast.error('Failed to check email existence');
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(SummaryApi.verifyOTP.url, {
                method: SummaryApi.verifyOTP.method, // Use PUT method for OTP verification
                headers: {
                    'Authorization': 'Bearer oakyaecom_units_916',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: data.email,
                    otp: data.otp,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to verify OTP');
            }

            const responseData = await response.json();

            if (responseData.status) {
                toast.success('OTP verified successfully. Logging in...');
                // Store customerid in a cookie without expiration
                Cookies.set('M8V5R1JQ3K0PWT6Z9LXA', btoa(encodeURIComponent(responseData.customerid)), {   //M8V5R1JQ3K0PWT6Z9LXA(Random 16-character hashcode)
                    expires: 365,  // Cookie will expire in 365 days (1 year)
                    secure: true,  // Cookie will only be sent over HTTPS
                    sameSite: 'Strict'  // Protect against CSRF
                });
                console.log(responseData.customerid)
                // Navigate to home page
                fetchUserAddToCart()
                navigate('/');
                
                
            } else {
                toast.error('Failed to verify OTP. Please try again.');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error('Failed to verify OTP');
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    return (
        <section id='login'>
            <div className='mx-auto container p-4'>
                <div className='bg-white p-5 w-full max-w-sm mx-auto'>
                    <div className='w-20 h-20 mx-auto'>
                        <img src={loginIcons} alt='login icons' />
                    </div>

                    {!emailSubmitted ? (
                        <form className='pt-6 flex flex-col gap-2' onSubmit={handleEmailSubmit}>
                            <div className='grid'>
                                <label>Email : </label>
                                <div className='bg-slate-100 p-2 border'>
                                    <input
                                        type='email'
                                        placeholder='enter email'
                                        name='email'
                                        value={data.email}
                                        onChange={handleOnChange}
                                        className='w-full h-full outline-none bg-transparent'
                                        required
                                    />
                                </div>
                            </div>

                            <button className='bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                                Next
                            </button>
                        </form>
                    ) : (
                        <form className='pt-6 flex flex-col gap-2' onSubmit={handleOtpSubmit}>
                            {emailExists && ( // Only show OTP input if email exists
                                <div>
                                    <label>OTP : </label>
                                    <div className='bg-slate-100 p-2 border flex'>
                                        <input
                                            type='text'
                                            placeholder='enter OTP'
                                            value={data.otp}
                                            name='otp'
                                            onChange={handleOnChange}
                                            className='w-full h-full outline-none bg-transparent'
                                            required
                                        />
                                    </div>
                                </div>
                            )}

                            {emailExists && ( // Only show verify button if email exists
                                <button className='bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-6'>
                                    Verify OTP
                                </button>
                            )}
                        </form>
                    )}

                    <p className='my-5'>
                        Don't have an account ?{' '}
                        <Link to={'/sign-up'} className=' text-red-600 hover:text-red-700 hover:underline'>
                            Sign up
                        </Link>
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Login;
